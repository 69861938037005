<template>
  <div id="app">
    <device v-show="!isHome && !isSetting"></device>
    <home class="home" v-show="isHome && !isSetting"></home> 
    <setting v-show="isSetting"></setting>

    <div style="position: absolute; left: 136px; top: 74px;">
      <img src="./img/2Main/logo.png">
    </div>

    <div style="position: absolute; left: 1158px; top: 590px;">
      <img v-show="!isHome && !isSetting" src="./img/2Main/div.png">
    </div>
  </div>
</template>

<script>
  import Device from './views/Device'
  import Home from './views/Home'
  import Setting from './views/Setting.vue'

  export default {
    name: 'App',
    data() {
      return {
        isHome:true,
        isSetting:false,
      }
    },
    components: {
      Device,
      Home,         
      Setting,   
    },
    mounted() {
      this.$bus.$on('enterMain',value => {
        console.log("enterMain",value);
        this.isHome = value;
      });
      this.$bus.$on('enterSetting',value => {
        this.isSetting = value;
      });
    },
    beforeDestroy() {
      this.$bus.$off('enterMain');
      this.$bus.$off('enterSetting');
    }
}
</script>

<style lang="less">
  * {
    margin: 0;
    padding: 0;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
    font-size: 12px;
    background: url('./img/2Main/main_bg.png') center fixed;
    background-size: contain;
    height: 100vh;
    width: 100%;
    min-width: 1800px;
    min-height: 900px;
  }
  .home {
    height: 100vh;
  }

  .el-notification {
    background-color: rgba(48, 45, 47,0.9) !important;
    border-color: rgba(163,163,163,0.6) !important;
    height: 100px;
    box-shadow: inset 0 0 3px 2px rgba(185,183,183,0.21) !important;
  }
  
  .el-notification__group {
    display: flex;
    align-items: center;
  }

  .el-notification__content {
    font-size: 18px !important;
  }
</style>
