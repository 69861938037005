<template>
  <div style="display: flex; flex-direction: column;"> 
    <h1 style="font-size: 18px; text-align: left;margin: 2px 0px;" lan="SensorSetting">Sensor设置</h1>
    <!-- <common-dpi-setting style="margin-top: 5px;"/> -->
    <dpi-setting/>
    <common-report-rate style="margin-top: 20px;"/>
    <common-sensor />
  </div>
</template>

<script>
import CommonReportRate from '../components/CommonReportRate.vue'
import CommonDpiSetting from '../components/CommonDpiSetting'
import CommonSensor from '../components/CommonSensor.vue'
import DpiSetting from '../components/DpiSetting'

export default {
  data() {
    return {
    }
  },
  components: {
    CommonDpiSetting,
    CommonReportRate,
    CommonSensor,
    DpiSetting,
  }
}
</script>

<style lang="less" scoped>
  :deep(.el-slider__button) {
    width: 18px;
    height: 18px;
    border: 0px;
    background-color: rgb(238,236,82);
    border-radius: 40%;
  }
</style>