<template>
  <div >
    <div :style="mouseImageStyle" style="display: flex; flex-direction: column;">
      <div style="display: flex;flex-direction: column;">
        <template
        v-if="loadFinish">
          <el-cascader 
            v-for="(item,index) in Locs" 
            :key="index"
            v-model="keys[index]"
            :options="keyOptions" 
            placeholder=""
            @change="selectChange(index, ...arguments)"
            @focus="handleFocus(index,$event)"
            :style="{top:item.top,left:item.left}"
            :show-all-levels="false">
            <template slot-scope="{ node,data }">
              <span 
              style="display: block;width: 100%;" 
              @click="handleSpanClick(node,data)">{{data.label}}</span>
            </template>    
          </el-cascader>    
        </template>  
      </div> 
    </div> 
    <!-- 火力键 -->
    <el-dialog
      title=""
      :visible.sync="dialogFireKeyVisible">
      <el-form :model="fireKeyForm">
        <el-form-item :label="times" label-width="200px" style="color:white">
          <el-input v-model="fireKeyForm.times" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="interval" label-width="200px" style="color:white">
          <el-input v-model="fireKeyForm.interval" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span style="color:white">{{tips}}</span>
      <span  slot="footer" class="dialog-footer">
        <el-button @click="handleFireKeyCancelClick" lan="Cancel">取 消</el-button>
        <el-button type="primary" @click="handleFireKeyOKClick" lan="OK">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 组合键 -->
    <el-dialog
      title=""
      :visible.sync="dialogComboKeyVisible"
      @close="comboKeyClose">
      <el-form :model="comboKeyForm">
        <el-form-item :label="inputKey" label-width="180px" style="color:white">
          <el-input 
          v-model="comboKeyForm.keyCode" 
          :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="" label-width="180px">
          <el-checkbox
          v-for="(item,index) in modifyItems"
          :key="index"
          :label="item"
          v-model="comboKeyForm.modifyValues"
          @change="handleModifyKeyChange"
          ></el-checkbox>
        </el-form-item>
      </el-form>
      <span  slot="footer" class="dialog-footer">
        <el-button @click="handleComboKeyCancelClick" lan="Cancel">取 消</el-button>
        <el-button type="primary" @click="handleComboKeyOKClick" lan="OK">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 保留左键 -->
    <el-dialog
      title=""
      :visible.sync="dialogReserveLeftVisible">
      <span style="color:white">{{keepLeftKey}}</span>
      <span  slot="footer" class="dialog-footer">
        <el-button @click="dialogReserveLeftVisible = false" lan="Cancel">取 消</el-button>
        <el-button type="primary" @click="dialogReserveLeftVisible = false" lan="OK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import HIDHandle from '../components/HIDHandle'
import HIDKey from './HIDKey';
export default {
  data() {
    return {
      loadFinish:true,
      keyOptions:[],
      keys:[[],[]],
      mouseKeys:[[],[]],
      Locs:[],
      img: "./img/2Main/dev1.png",
      defaultKeys:[],
      lastKeys:[],
      macro:[],

      selectIndex:0,
      dialogFireKeyVisible: false,
      fireKeyForm: {
        times: "2",
        interval: "10",
      },

      modifyItems:["Shift","Ctrl","Alt","Win"],
      dialogComboKeyVisible: false,
      comboKeyForm: {
        keyCode:"",
        modifyValues:[],
      },
      normalKeyCode:"",

      dialogReserveLeftVisible:false,
      leftKeys:[],

      times:"次数（0-3）",
      interval:"间隔(10-255)",
      tips:"次数设置为0时，按下按键一直发，松开按键结束",
      inputKey:"请输入单键或组合键",
      keepLeftKey:"左键必须保留"
    }
  },
  methods: {
    handleFocus(index,event) {
       this.selectIndex = this.defaultKeys[index].Index;
       this.lastKeys = this.keys[index];

       this.leftKeys = [];
       for(var i = 0;i < this.keys.length;i++) {
        if(this.keys[i][0] == 0x01 &&
          this.keys[i][1] == 0x0100) {
            this.leftKeys.push(i);
          }
       }

       if(this.leftKeys.length == 1) {
        if(this.leftKeys[0] == index) {
          this.dialogReserveLeftVisible = true;
        }
       }
       console.log("focus:",this.keys[index],index,this.selectIndex,this.lastKeys);
    },
    handleSpanClick(node,data) {
      console.log("span Click",node,data);

      if((node.hasChildren == false) && (node.parent == null)){
        if(data.value == 4) {
          this.dialogFireKeyVisible = true;
        } 

        if(data.value == 5) {
            this.dialogComboKeyVisible = true;
            window.addEventListener('keydown', this.handleEditKeyDown);
        } 
      }
    },
    async selectChange(selectIndex) {
      let data = [];
      var value = this.keys[selectIndex];
      var index = Number.parseInt(this.defaultKeys[selectIndex].Index);
        
      this.selectIndex = index;
      var needSend = true;
      data[0] = Number.parseInt(value[0]);
      if(data[0] == 0x05)//快捷键或者多媒体
      {

        data[1] = 0;
        data[2] = 0;

        this.dialogComboKeyVisible = true;
        window.addEventListener('keydown', this.handleEditKeyDown);
        needSend = false;     
      }
      else if(data[0] == 15) {
        data[0] = 5;
        data[1] = 0;
        data[2] = 0;
        await HIDHandle.setMultimedia(index,value[1]);
      }
      else if(data[0] == 0x06)//设置宏
      {
        var cycleTimes = Number.parseInt(this.macro[Number.parseInt(value[1])].cycleTimes);
        data[1] = index;
        data[2] = cycleTimes;
        console.log("setMacro:",cycleTimes,this.macro[Number.parseInt(value[1])])
        await HIDHandle.setMacro(index,this.macro[Number.parseInt(value[1])]);

        HIDHandle.mouseCfg.macro[index].name = this.macro[Number.parseInt(value[1])].name;
        HIDHandle.mouseCfg.macro[index].contexts = this.macro[Number.parseInt(value[1])].contexts;
      }
      else if(data[0] == 0x04)//火力键
      {
        this.dialogFireKeyVisible = true;
        needSend = false;
      }
      else
      {
        let tmp = Number.parseInt(value[1]);
        data[1] = tmp >> 8;
        data[2] = tmp & 0xFF;
      }

      console.log(`selectChange`,this.keys,index,value,data); 
      if(needSend)
        await HIDHandle.setKeyFunction(index,data);
    },
    handleFireKeyCancelClick() {
      console.log(`handleFireKeyCancelClick`,this.keys,this.lastKeys); 
      this.keys[this.defaultKeys[this.selectIndex].Index] = this.lastKeys;
      console.log(`handleFireKeyCancelClick`,this.keys,this.lastKeys); 
      this.dialogFireKeyVisible = false;
    },
    async handleFireKeyOKClick() {
      var data = [];
      data[0] = 0x04;
      data[1] = this.fireKeyForm.interval;
      data[2] = this.fireKeyForm.times;
      await HIDHandle.setKeyFunction(this.selectIndex,data);
      this.dialogFireKeyVisible = false;
    },
    handleComboKeyCancelClick() {
      this.keys[this.defaultKeys[this.selectIndex].Index] = this.lastKeys;
      window.removeEventListener('keydown', this.handleEditKeyDown);
      this.dialogComboKeyVisible = false;
    },
    async handleComboKeyOKClick() {
      var shortCutKey = [];

      for(var i = 0;i < this.comboKeyForm.modifyValues.length;i++)
      {
        if(this.comboKeyForm.modifyValues[i] == "Ctrl")
          shortCutKey.push("ControlLeft");
 
        if(this.comboKeyForm.modifyValues[i] == "Shift")
          shortCutKey.push("ShiftLeft");

        if(this.comboKeyForm.modifyValues[i] == "Alt")
          shortCutKey.push("AltLeft");

        if(this.comboKeyForm.modifyValues[i] == "Win")
          shortCutKey.push("MetaLeft");
      }

      if(this.normalKeyCode)
        shortCutKey.push(this.normalKeyCode);

      await HIDHandle.setShortCutKey(this.selectIndex,shortCutKey);

      var data = [];
      data[0] = 0x05;
      data[1] = 0x00;
      data[2] = 0x00;
      await HIDHandle.setKeyFunction(this.selectIndex,data);
      window.removeEventListener('keydown', this.handleEditKeyDown);
      this.dialogComboKeyVisible = false;
    },
    handleModifyKeyChange(value) {
      if(this.comboKeyForm.modifyValues.length > 2)
        this.comboKeyForm.modifyValues.pop();
    },
    comboKeyClose() {
      window.removeEventListener('keydown', this.handleEditKeyDown);
    },
    handleEditKeyDown(event) {
      var hid = HIDKey.keyToHID(event.code);
      if(hid.type == 1) {
        this.comboKeyForm.keyCode = HIDKey.keyToHID(event.code).text;
        this.normalKeyCode = event.code;
      }

      console.log(event,this.comboKeyForm.keyCode,hid);
      event.preventDefault();     
    },
    handleEditKeyInput() {
      console.log("handleEditKeyInput",this.comboKeyForm.keyCode);
    },
    async updateMacro(index) {
      for(var i = 0;i < this.keys.length; i++){
        if(typeof HIDHandle.mouseCfg.macro[i].name != 'undefined') {
          if(HIDHandle.mouseCfg.macro[i].name == this.macro[index].name) {
            await HIDHandle.setMacro(i,this.macro[index]);

            HIDHandle.mouseCfg.macro[i].name = this.macro[index].name;
            HIDHandle.mouseCfg.macro[i].contexts = this.macro[index].contexts;

            var cycleTimes = Number.parseInt(this.macro[index].cycleTimes);
            var data = [];
            data[0] = 0x06;
            data[1] = i;
            data[2] = cycleTimes;
            await HIDHandle.setKeyFunction(i,data);
          }
        }
      }
    }
  },
  computed: {
    mouseImageStyle() {
      return {
        background:`url(${this.img}) no-repeat center`,
        backgroundSize: `100% 100%`,
        height: `100%`
      }
    }
  },
  mounted() {
    this.$bus.$on('setMouseImageIndex',value => {
      this.img = "./img/2Main/dev" + (value + 1) + ".png";
      console.log("mouse image:",this.img);
    });

    this.$bus.$on('getDeviceInfo',value => {
      this.keys.length = value.Keys.length;
      for(var i = 0;i < value.Keys.length;i++)
      {
         this.keys[i] = value.Keys[i].value;
      }
      this.defaultKeys = value.Keys;
      console.log('getDeviceInfo keys',this.keyOptions,this.keys,value.Keys);
      this.Locs = [];
      for(var i = 0;i < this.keys.length;i++)
      {
        var loc = 
        {
          position: 'absolute',
          left: value.Keys[i].Loc[0]  + 'px',
          top: value.Keys[i].Loc[1] - i * 40 - 20 + 'px'
        }
        this.Locs.push(loc);
      }
      
      this.loadFinish = true;
      console.log('Locs',this.Locs);
    });
    
    this.$bus.$on('updateUI',value => { 
      this.loadFinish = false;
      for(var i = 0;i < this.keys.length;i++)
      {
        this.mouseKeys[Number.parseInt(this.defaultKeys[i].Index)] = value.keys[i];
        if((value.keys[i][0] == 7) || 
           (value.keys[i][0] == 9) || 
           (value.keys[i][0] == 4) ||
           (value.keys[i][0] == 0)) 
        {
          this.keys[Number.parseInt(this.defaultKeys[i].Index)] = value.keys[i][0]; 
        }
        else if(value.keys[i][0] == 6) 
        {
          for(var k = 0; k < this.keyOptions.length; k++)
          {
            if(this.keyOptions[k].value == 6)
            {
              var found = false;
              if(typeof this.keyOptions[k].children !== "undefined" && this.keyOptions[k].children)
              {

                if(this.keyOptions[k].children.length > 0)
                {
                  for(let key in this.keyOptions[k].children) {
                    if((this.keyOptions[k].children[key].label == value.macro[i].name))
                    {
                      var macrokey = ["6",this.keyOptions[k].children[key].value];
                      this.keys[Number.parseInt(this.defaultKeys[i].Index)] = macrokey;
                      found = true;
                      break;
                    }
                  }
                }
              }

              if(found == false)
              {
                var children = {
                  value : this.keyOptions[k].children.length,
                  label : value.macro[i].name,
                }
                this.keyOptions[k].children.push(children);

                var macro = {
                  cycleTimes : value.keys[i][1] & 0xFF,
                  name : value.macro[i].name,
                  contexts : value.macro[i].contexts,
                }

                this.$bus.$emit("addMacro",macro);

                var macrokey = ["6",this.keyOptions[k].children.length - 1];
                this.keys[Number.parseInt(this.defaultKeys[i].Index)] = macrokey;
              
                console.log("new macro:",this.keyOptions[k].children,value.keys,macro);
                this.$bus.$emit("newMacro",macro);
              }
            }
          }
        }
        else if(value.keys[i][0] == 5)
        {
          console.log("shortCutKey:",i,value.shortCutKey[i]);
          var key = [];
          if(value.shortCutKey[i].isMedia)
          {
            key.push("15");
            key.push(value.shortCutKey[i].contexts[0].value);
          }
          else {
            key.push("5");
          }

          this.keys[Number.parseInt(this.defaultKeys[i].Index)] = key;
        }
        else
          this.keys[Number.parseInt(this.defaultKeys[i].Index)] = value.keys[i];  
      }

      console.log("keys:",this.keys,this.defaultKeys,this.mouseKeys,value);
      this.loadFinish = true;
    }); 

    this.$bus.$on("deleteMacro",async value => {
      console.log("deleteMacro",value,this.keys);

      this.loadFinish = false;
      for(var i = 0; i < this.keyOptions.length; i++) {
        if(this.keyOptions[i].value == 6)
        {
          for(var j = 0;j < this.keyOptions[i].children.length;j++)
          {
            if(this.keyOptions[i].children[j].label == value.name) {
              this.keyOptions[i].children.pop(j);
            }
          }
        }
      }
      this.loadFinish = true;

      for(var i = 0;i < this.keys.length;i++) {
        if(this.keys[i][0] == 0x06) {           
          var index = Number.parseInt(this.defaultKeys[i].Index);
          if(value.name == HIDHandle.mouseCfg.macro[index].name) {
            var data = [];
            data[0] = Number.parseInt(this.defaultKeys[i].value[0]);
            let tmp = Number.parseInt(this.defaultKeys[i].value[1]);
            data[1] = tmp >> 8;
            data[2] = tmp & 0xFF;

            this.loadFinish = false;
            var key = [this.defaultKeys[i].value[0],this.defaultKeys[i].value[1]];
            this.keys[i] = key;
            console.log("macro index",i,this.defaultKeys[i],this.keys[i]);
            this.loadFinish = true;
            await HIDHandle.setKeyFunction(index,data);
            await HIDHandle.restoreMacro(index);
          }
        }
      }

      console.log("deleteMacro2",this.keys);
    }); 

    this.$bus.$on("updateMacroName",async (oldName,newName) => {
      console.log("updateMacroName",oldName,newName,this.keyOptions);
      this.loadFinish = false;
      for(var i = 0; i < this.keyOptions.length; i++) {
        if(this.keyOptions[i].value == 6)
        {
          for(var j = 0;j < this.keyOptions[i].children.length;j++)
          {
            if(this.keyOptions[i].children[j].label == oldName) {
              this.keyOptions[i].children[j].label = newName;
            }
          }
        }
      }
      this.loadFinish = true;

      if(HIDHandle.deviceInfo.online == true) {
        for(var i = 0;i < this.keys.length;i++) {
          if(this.keys[i][0] == 0x06) {           
            var index = Number.parseInt(this.defaultKeys[i].Index);
            if(oldName == HIDHandle.mouseCfg.macro[index].name) {
              HIDHandle.mouseCfg.macro[index].name = newName;
              await HIDHandle.setMacroName(index,newName);
            }
          }
        }
      }
    });    

    this.$bus.$on("languageChange",value => {
      import(`../lan/${value}.json`).then(lang => {
        this.loadFinish = false;
        this.keyOptions = lang.KeysFun;
        for(var i = 0; i < this.keyOptions.length; i++)
        {
          if(this.keyOptions[i].value == 6)
          {
            this.keyOptions[i].children = [];
            for(var j = 0;j < this.macro.length;j++)
            {
              var children = {
                value:j,
                label:this.macro[j].name
              }
              this.keyOptions[i].children.push(children);
            }
          }
        }

        this.times = lang.Dialog.FireKeyTimes;
        this.interval = lang.Dialog.FireKeysInterval;
        this.tips = lang.Dialog.FireKeyTips;
        this.inputKey = lang.Dialog.InputKey;
        this.keepLeftKey = lang.Dialog.KeepLeftKey;
        this.loadFinish = true;
      });
    });

    this.$bus.$on("saveMacro",index => {  
      this.updateMacro(index);
    })
  },
  created() {
    this.$bus.$on("updateMacro",value => {
      this.macro = value;
      for(var i = 0; i < this.keyOptions.length; i++)
      {
        if(this.keyOptions[i].value == 6)
        {
          this.keyOptions[i].children = [];
          for(var j = 0;j < value.length;j++)
          {
            var children = {
              value:j,
              label:value[j].name
            }
            this.keyOptions[i].children.push(children);
          }
          console.log("update children:",this.keyOptions[i].children);
        }
      }
    })
  },
  beforeDestroy() {
    this.$bus.$off('getDeviceInfo');
    this.$bus.$off('updateUI');
    this.$bus.$off('updateMacro');
    this.$bus.$off('languageChange');
    this.$bus.$off('setMouseImageIndex');
  }
}
</script>

<style lang="less" scoped>
  .pageMouse {
    background-size: 100% 100%;
    height: 100%;
  }


  .el-cascader {
    width: 200px;
    height: 40px;
  }

  :deep(.el-input__inner) {
    background: transparent;
    color:white;
    border-radius: 0px;
    border-style: none;
    padding: 0px 30px;
    height: 20px;
  }

  :deep(.el-input__suffix) {
    right:auto;
    left:0px;
  }

  :deep(.el-dialog) {
    background-image: url('../img/2Main/dialog.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 30%;
    .el-button {
      height: 22px;
      font-size: 9px;
      color:white;
      background-color: transparent;
      padding: 0px 20px;
      &:hover {
        color:#ffffff;
        background-color: transparent;
        border-color: rgb(238,236,82);
      }
      &:focus {
        color:#ffffff;
        background-color: transparent;
        border-color: rgb(238,236,82);
      }
    }

    .el-input__inner {
      border: 1px solid #DCDFE6
    }
  }


  :deep(.el-form-item__label) {
    color:white;
  }

  :deep(.el-input.is-disabled .el-input__inner) {
    border: 1px solid;
    color: black;
    cursor: text;
    text-align: center;
  }
</style>