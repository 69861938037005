<template>
  <div>
    <div style="display: flex; flex-direction: column;">
      <div style="display: flex; flex-direction: row; align-items: baseline;justify-content: space-between;">
        <div style="display: flex; flex-direction: row; align-items: baseline;">
          <span lan="LOD">LOD</span>
          <div style="display: flex;flex-direction: column;margin: 0px 75px;">
            <div class="slider_runway" id="runway" style="width:120px">
            </div>
            <div style="position: relative; display: flex;flex-direction: row; width: 120px;justify-content: space-around;">
              <div class="sliderButton" v-for="(value, index) in lods" :key="index" style="display: flex; flex-direction: column; align-items: center;">
                <label 
                style="top:-50px;position:relative"
                :style="{color:index == lod?'#eeec52':'rgba(255,255,255,0.2)'}">
                {{lods[index].option}}
                </label>
                <el-button
                style="top:-28px"
                :style="{
                  backgroundColor:index == lod?'#eeec52':'#949494',
                  borderColor:index == lod?'#eeec52':'#949494'}"
                @click="handlerLODClick(index)">
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <el-checkbox v-model="ripple" @change="handleRippleChange"><span lan="Ripple">波纹控制</span></el-checkbox>
        <el-checkbox v-model="angle" @change="handleAngleChange"><span lan="Angle">直线修正</span></el-checkbox>
      </div>
      <div>
        <div style="display: flex; flex-direction: row; align-items: center;justify-content: space-between;height:80px">
          <el-checkbox 
          v-show="longDistanceShow"
          v-model="longDistance" 
          @change="handleLongDistanceChange"
          style="margin-top: -15px;"><span lan="LongDistance">远距离模式</span></el-checkbox>
          <!-- <div style="display: flex; flex-direction: row; align-items: baseline;" :style="{display:disableSleepTime?'none':'flex'}"> -->
          <div style="display: flex; flex-direction: row; align-items: baseline;">
            <span lan="SleepTime">休眠时间</span>
            <div style="display: flex;flex-direction: column;margin: 0px 16px;">
            <div class="slider_runway" id="runway" style="width:300px">
            </div>
            <div style="position: relative; display: flex;flex-direction: row; width: 300px;justify-content: space-around;">
              <div class="sliderButton" v-for="(value, index) in sleepTimes" :key="index" style="display: flex; flex-direction: column; align-items: center;">
                <label 
                style="top:-50px;position:relative"
                :style="{color:index == sleepTime?'#eeec52':'rgba(255,255,255,0.2)'}">
                {{sleepTimes[index].option}}
                </label>
                <el-button
                style="top:-28px"
                :style="{
                  backgroundColor:index == sleepTime?'#eeec52':'#949494',
                  borderColor:index == sleepTime?'#eeec52':'#949494'}"
                @click="handlerSleepTimeClick(index)">
                </el-button>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 远距离模式 -->
    <el-dialog
      title=""
      :visible.sync="dialogLongDistanceVisible">
      <span style="color:white">{{longDistanceTips}}</span>
      <span  slot="footer" class="dialog-footer">
        <el-button @click="handleLongDistaceCancel" lan="Cancel">取 消</el-button>
        <el-button type="primary" @click="handleLongDistaceConfirm" lan="OK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import HIDHandle from '../components/HIDHandle'

export default {
  data() {
    return {
      lod:1,
      lods:[],
      mode:0,
      modes:[
        {
          value:0,
          text:"performance"
        },
        {
          value:1,
          text:"high performance"
        }
      ],
      ripple:false,
      angle:false,
      motion:false,
      longDistance:false,
      performance:false,
      disableSleepTime:true,
      sleepTime:2,
      sleepTimes:[],
      longDistanceShow:false,
      longDistanceTips:"远距离模式下，距离会更远，抗干扰能力更强，相应工作电流会加大，使用时间减少",
      dialogLongDistanceVisible:false,
    }
  },
  methods: {
    async handlerLODClick(index) {
      this.lod = index;
      await HIDHandle.setLOD(this.lods[index].value);
    },
    async handleModeChange() {
      await HIDHandle.setSensorMode(this.mode);
    },
    async handleRippleChange() {
      await HIDHandle.setRipple(this.ripple);
    },
    async handleAngleChange() {
      await HIDHandle.setFixLine(this.angle);
    },
    async handleMotionChange() {
      await HIDHandle.setMotionSync(this.motion);
    },
    async handleLongDistanceChange() {
      var tmp = this.longDistance;
      if(tmp) {
        this.dialogLongDistanceVisible = true;
      }
      else {
        if(await HIDHandle.setLongDistance(0)) {
          this.longDistance = false;
        }
      }
    },
    handleLongDistaceCancel() {
      this.longDistance = false;
      this.dialogLongDistanceVisible = false;
    },
    async handleLongDistaceConfirm() {
      this.longDistance = await HIDHandle.setLongDistance(1);
      this.longDistance = true;
      this.dialogLongDistanceVisible = false;
    },
    async handlePerformanceChange() {
      await HIDHandle.setPerformanceState(this.performance);
    },
    async handlerSleepTimeClick(index) {
      this.sleepTime = index;
      await HIDHandle.setPerformanceTime(this.sleepTimes[index].value);
    }
  },
  mounted() {
    this.$bus.$on("updateUI",value => {
      for(var i = 0;i < this.lods.length;i++) {
        if(this.lods[i].value == value.sensor.lod) {
          this.lod = i;
          break;
        }
      }

      for(var i = 0;i < this.sleepTimes.length;i++) {
        if(this.sleepTimes[i].value == value.sensor.sleepTime) {
          this.sleepTime = i;
          break;
        }
      }

      this.mode = value.sensor.mode;
      this.ripple = value.sensor.ripple;
      this.angle = value.sensor.angle;
      this.motion = value.sensor.motionSync;
      this.performance = value.sensor.performance;
      this.longDistanceShow = value.supportLongDistance;
      if(this.longDistanceShow) {
        this.longDistance = value.longDistance;
      }

      //竞技模式下不可以修改休眠时间
      this.disableSleepTime = value.sensor.performance;
      console.log("updateUI sleep:",this.disableSleepTime);
    })
  },
  created() {
    this.$bus.$on("languageChange",value => {
      import(`../lan/${value}.json`).then(lang => {
        this.lods = lang.LODOption;
        this.sleepTimes = lang.PerformanceOption;
        this.longDistanceTips = lang.Dialog.LongDistanceTips;
      });
    });

    this.$bus.$on("updatePerformance",value => {
      this.disableSleepTime = value;
    });    
  },
  beforeDestroy() {
    this.$bus.$off("updateUI");
    this.$bus.$off("languageChange");
  }
}
</script>

<style lang="less" scoped>

.slider_runway {
    height: 6px;
    margin: 16px 0;
    background-color: #949494;
    border-radius: 3px;
    position: relative;
  }

  .sliderButton::v-deep .el-button {
    height: 18px;
    width: 18px;
    border-radius: 40%;
    padding: 0px 0px;
    position: absolute;
  }

  :deep(.disabled) {
    cursor: no-drop;
    pointer-events: none; /* 这个属性可以阻止点击事件 */
  }

  :deep(.el-checkbox__input .el-checkbox__inner) {
    background-color: transparent;
    border:1px solid white;
    border-radius: 4px;
  }

  :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
    background-color: transparent;
  }

  :deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
    background-color: transparent;
  }

  :deep(.el-checkbox__input.is-checked+.el-checkbox__label) {
    color:white;
  }

  :deep(.el-checkbox__label) {
    color:white;
  }

  .el-select {
    background: transparent;
  }
  :deep(.el-input__inner) {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    font-size: 12px;
  }

  :deep(.el-dialog) {
    background-image: url('../img/2Main/dialog.png');
    background-repeat: no-repeat;
    background-size: cover;

    .el-button {
      height: 22px;
      font-size: 9px;
      color:white;
      background-color: transparent;
      padding: 0px 20px;
      &:hover {
        color:#ffffff;
        background-color: transparent;
        border-color: rgb(238,236,82);
      }
      &:focus {
        color:#ffffff;
        background-color: transparent;
        border-color: rgb(238,236,82);
      }
    }
  }

  :deep(.el-form-item__label) {
    color:white;
  }
</style>