var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center","justify-content":"space-between"}},[_c('span',{attrs:{"lan":"DPISetting"}},[_vm._v("DPI设置")]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"baseline"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"slider_runway",style:({width:_vm.silerLen + 18 + 'px'}),attrs:{"id":"runway"}}),_c('div',{staticStyle:{"position":"relative","display":"flex","flex-direction":"row"},style:({width:_vm.silerLen + 'px'})},_vm._l((_vm.dpiParams),function(value,index){return _c('div',{key:index,staticStyle:{"display":"flex","flex-direction":"column","align-items":"center"}},[_c('label',{style:({top:'-50px', 
            left:  Number(_vm.dpiParams[index].left.replace(/px$/, '')) - 23 * index + 'px',
            color:_vm.currentDpi == index? _vm.dpiParams[index].color : 'rgba(255,255,255,0.2)',
            position:'relative'}),on:{"dblclick":_vm.handleLabelDbClick}},[_vm._v(" "+_vm._s(_vm.dpiParams[index].value)+" ")]),_c('el-button',{directives:[{name:"draggable",rawName:"v-draggable",value:(_vm.setDpiValue),expression:"setDpiValue"}],style:({top:'-28px', 
            left:_vm.dpiParams[index].left,
            backgroundColor:_vm.currentDpi == index? _vm.dpiParams[index].color : '#949494',
            borderColor:_vm.currentDpi == index? _vm.dpiParams[index].color : '#949494',}),attrs:{"id":'btn' + index},on:{"click":function($event){return _vm.handleClick(index)}}},[_vm._v(" "+_vm._s(index + 1)+" ")])],1)}),0)]),_c('el-input-number',{staticStyle:{"width":"80px"},attrs:{"min":1,"max":_vm.maxDpi,"size":"mini"},on:{"change":_vm.handleDpiCountChange},model:{value:(_vm.dpiCount),callback:function ($$v) {_vm.dpiCount=$$v},expression:"dpiCount"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }