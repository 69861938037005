<template>
  <div>
    <div style="display: flex; flex-direction: row;align-items: center;justify-content: space-evenly;">
      <div class="inner">
        <el-checkbox 
        v-model="motionSync" 
        @change="handleMotionSyncChange"
        style="background-color: transparent;border: 0px;font-size: 32px; padding: 12px 20px;"
        :style="{color : motionSync == true ? '#eeec52' : 'white'}"
        class="iconfont icon-MotionSync"></el-checkbox>
        <span lan="MotionSync">Motion Sync</span>
      </div>
      <div class="inner">
        <i 
        @click="handlePerformanceClick"
        style="background-color: transparent;border: 0px;font-size: 32px; padding: 12px 20px;"
        :style="{color : performance == true ? '#eeec52' : 'white'}"
        class="iconfont icon-Mode"></i>
        <span>{{performanceText}}</span>
      </div>
      <div class="inner">
        <span lan="DebounceTime">Debounce Time</span>
        <el-input-number 
        v-model="debounceTime"
        :min="0"
        :max="30"
        @change="handleDebounceChange" 
        style="margin-top: 10px;" 
        size="mini"></el-input-number>
      </div>
      <div class="inner">
        <el-button 
        @click="handlePairClick"
        style="background-color: transparent;border: 0px;color: white;font-size: 32px; padding: 12px 20px;" 
        class="iconfont icon-Pair"></el-button>
        <span lan="MousePair">鼠标配对</span>
      </div>
      <div class="inner">
        <el-button 
        @click="handleRestoreClick"
        style="background-color: transparent;border: 0px;color: white;font-size: 32px; padding: 12px 20px;" 
        class="iconfont icon-Restore"></el-button>
        <span lan="Restore">恢复出厂设置</span>
      </div>
    </div>
    <el-dialog
      :visible.sync="pairDialogVisible"
      :close-on-click-modal="false"
      @close="handlePairClose"
      width="30%">
      <span style="color:white">{{pairText}}</span>
      <span  slot="footer" class="dialog-footer">
        <el-button @click="handleCancelClick" lan="Cancel">取 消</el-button>
        <el-button type="primary" @click="handleStartPairClick" @mouseenter="handleStartPairMouseEnter">{{startPair}}</el-button>
      </span>
    </el-dialog>  
    <common-dialog 
    title=""
    :visible.sync="dialogVisible"
    :content="dialogText"
    :showButton="dialogButton"
    @dialog-result="dialogResult"></common-dialog>
  </div>
</template>

<script>
import HIDHandle from '../components/HIDHandle'
import CommonDialog from '../components/CommonDialog'

export default {
  components: {
    CommonDialog
  },
  data() {
    return {
      debounceTime:8,
      performance:true,
      motionSync:false,
      performanceText:"普通模式",

      pairDialogVisible:false,
      pairText:"",

      dialogType:"performance",
      dialogVisible:false,
      dialogButton:true,
      dialogText:"",
      startPair:"开始配对",
      pairResult:HIDHandle.pairResult,
      pairing:false,

      deounceTipsFlag:false,

      language:{},

      isRecording:false,
    }
  },
  methods: {
    async handleDebounceChange() {
      if(this.debounceTime < 2) {
        if(this.deounceTipsFlag == false) {
          this.dialogText = this.language.Dialog.DebounceTips;
          this.dialogType = "debounce";
          this.dialogVisible = true;
          localStorage.setItem('debounceTips',JSON.stringify(true));
          this.deounceTipsFlag = true;
        }
        else {
          await HIDHandle.setDebounceTime(this.debounceTime);
        }
      }
      else {
        await HIDHandle.setDebounceTime(this.debounceTime);
      }

    },
    async handleMotionSyncChange() {
      await HIDHandle.setMotionSync(this.motionSync);
    },
    async handlePerformanceClick() {
      if(this.performance) {
        this.performance = false;
        await HIDHandle.setPerformanceState(false);
        this.$bus.$emit("updatePerformance",this.performance);  
      }
      else {
        this.dialogText = this.language.Dialog.Performance;
        this.dialogType = "performance";
        this.dialogVisible = true;
      }
    },
    handlePairClick() {
      this.pairText = this.language.Dialog.PairTips;
      this.pairDialogVisible = true;
    },
    async handleStartPairClick() {
    if(this.pairResult.pairStatus == 0x03) {
      if(this.pairing == false) {
        this.pairDialogVisible = false;
      }
    }
    else if(this.pairResult.pairStatus == 0x02 || this.pairResult.pairStatus == 0x00){
        if(this.pairing == false) {
          await HIDHandle.enterPairMode();
          this.pairing = true;
        }
      }
    },
    handleStartPairMouseEnter() {
      console.log("handleStartPairMouseEnter");
      if(this.pairResult.pairStatus == 0x02) {
        this.startPair = this.language.StartPair;
      }
    },
    async getPairResult() {
      await HIDHandle.getPairResult();
    },
    handlePairClose() {
      this.pairResult.pairStatus = 0x00;
      this.startPair = this.language.StartPair;
    },
    handleCancelClick() {
      if(this.pairing == false) {
        this.pairDialogVisible = false;
      }
    },
    async dialogResult(result) {
      this.dialogVisible = false; 
      console.log("dialogResult",result);
      if(this.dialogType == "performance") {
        if(result == 1) {
          await HIDHandle.setPerformanceState(true);        
          this.performance = true;
          this.$bus.$emit("updatePerformance",this.performance);   
        }        
      }
      else if(this.dialogType == "restore") {
        if(result == 1) {
          await HIDHandle.setDeviceRestore();
        }
      }
      else if(this.dialogType == "debounce") {
        if(result == 1) {
          await HIDHandle.setDebounceTime(this.debounceTime);
        }
      }
    },
    handleRestoreClick() {
      this.dialogText = this.language.Dialog.Restore;
      this.dialogType = "restore";
      this.dialogVisible = true;
    },
    async handleKeyDown(event) {   
        if(this.pairDialogVisible== true) {
          if (event.code === 'Space') {
            this.handleStartPairClick();
          }
        }
      },
  },
  watch: {
    performance: {
      handler() {
        if(this.performance) {
          this.performanceText = this.language.GameMode;
        }
        else {
          this.performanceText = this.language.NormalMode;
        }
      }
    },
    "pairResult.pairStatus": {
      handler() {
        if(this.pairResult.pairStatus == 0x03) {
          this.startPair = this.language.PairSuccess;
          this.pairing = false;
        }
        else if(this.pairResult.pairStatus == 0x02) {
          this.startPair = this.language.PairFail;
          this.pairing = false;
        }
        else if(this.pairResult.pairStatus == 0x01) {
          this.startPair = this.language.Pairing;
        }
      }
    }
  },
  created() {
    this.$bus.$on('enterMain',value => {
      this.deounceTipsFlag = false;
    });
  },
  mounted() {
    this.$bus.$on("updateUI",value => {
      this.debounceTime = value.debounceTime;
      this.motionSync = value.sensor.motionSync;
      this.performance = value.sensor.performance;
    })

    this.$bus.$on("languageChange",value => {
      import(`../lan/${value}.json`).then(lang => {
        this.language = lang;
        this.startPair = this.language.StartPair;
        if(this.performance) {
          this.performanceText = this.language.GameMode;
        }
        else {
          this.performanceText = this.language.NormalMode;
        }
      });
    }) 

    this.$bus.$on("isRecording",value => {
      this.isRecording = value;
    }) 

    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
    this.$bus.$off("updateUI");
    this.$bus.$off("languageChange");
    this.$bus.$off("enterMain");
  }
}
</script>

<style lang="less" scoped>
  :deep(.el-dialog) {
    background-image: url('../img/2Main/dialog.png');
    background-repeat: no-repeat;
    background-size: cover;

    .el-button {
      height: 22px;
      font-size: 9px;
      color:white;
      background-color: transparent;
      padding: 0px 20px;
      &:hover {
        color:#ffffff;
        background-color: transparent;
        border-color: rgb(238,236,82);
      }
      &:focus {
        color:#ffffff;
        background-color: transparent;
        border-color: rgb(238,236,82);
      }
    }
  }

  .inner {
    display: flex; 
    flex-direction: column;
    align-items: center;
  }

  .el-input-number {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    color:white;
    border: 0px;
  }

  :deep(.el-input-number__decrease, .el-input-number__increase) {
    background: transparent;
    color:white;
    border: 0px;
  }

  :deep(.el-input-number__increase) {
    background: transparent;
    color:white;
    border: 0px;
  }

  :deep(.el-checkbox__inner) {
    display: none;
  }
</style>