<template>
  <div>
    <div style="display: flex; flex-direction: column;justify-content: flex-end;">
      <div style="display: flex; justify-content: flex-end;">
        <el-button icon="el-icon-back" size="medium" style="font-size: 20px; margin: 20px;" @click="handleBackClick"></el-button>
      </div>
      <div style="display: flex; flex-direction: column;align-items: center;">
        <div style="display: flex; flex-direction: column; width: 700px; align-items: flex-start;gap: 25px;margin: 80px;">
          <h1 class="fontSize_Title" lan="Setting" style="margin-bottom: 20px;">设置</h1>
          <h1 class="fontSize_Title" lan="SelectLanguage">选择语言</h1>
          <div style="display: flex; flex-direction: row; justify-content: space-between; width: 400px; margin-bottom: 20px; margin-top: 20px;">
            <el-radio
            v-for="(value,index) in languages"
            :key="index"
            :label="value.value"
            v-model="language"
            @change="handleLanguageChange"
            style="width: 100px; display: flex; margin-top: 10px;"> 
            <div style="display: flex; flex-direction: row; align-items: center;">
              <img :src="value.path" 
              style="width: 50px;height: 35px;"> 
              <span 
              @click="handleLanguageClick(value.value)" 
              style="font-size: 20px; margin-left: 5px;">{{value.text}}</span> 
            </div>
            </el-radio>
          </div>
          <!-- <h1 class="fontSize_Title" lan="DeviceSetting">设备设置</h1>
          <el-button size="mini" @click="handlePairClick" lan="Pair">立即配对</el-button>
          <el-button size="mini" @click="hangleRestoreClick" lan="Restore" style="margin-left: 0px;" >恢复出厂设置</el-button> -->
          <h1 class="fontSize_Title" lan="DeviceInfo">设置信息</h1>
          <div style="display: flex; flex-direction: row; align-content: center; justify-content: space-between; width: 400px;">
            <p class="fontSize_16px" lan="DongleVersion">接收器版本:</p>
            <p class="fontSize_16px">{{dongleVersion}}</p>
            <el-button v-show="updateVisiable" size="mini">Update Now</el-button>
          </div>
          <div style="display: flex; flex-direction: row; align-content: center; justify-content: space-between; width: 400px;">
            <p class="fontSize_16px" lan="MouseVersion">鼠标版本:</p>
            <p class="fontSize_16px">{{mouseVersion}}</p>
            <el-button v-show="updateVisiable" size="mini">Update Now</el-button>
          </div>  
          <a v-show="updateLinkShow" :href="updateHref" lan="DeviceUpdateTips" style="color:white"> 设备需要更新?点击下载更新程序! </a>
          <el-progress 
          v-show="updateVisiable"
          :percentage="updatePrecentage" 
          color="#0f0"
          :show-text="false" 
          style="width: 580px;"></el-progress>   
          <p v-show="!updateResult">Update failed!Please try updating again later.</p>
          <span v-show="updateVisiable" style="white-space: pre-wrap;text-align: left;" class="fontSize_16px">{{changeLog}}</span>   
        </div>
      </div>     
    </div>  
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%">
      <span>{{dialogText}}</span>
      <span  slot="footer" class="dialog-footer">
        <el-button @click="handleCancelClick">取 消</el-button>
        <el-button type="primary" @click="handleStartPairClick" @mouseenter="handleStartPairMouseEnter">{{startPair}}</el-button>
      </span>
    </el-dialog>  
  </div>
</template>

<script>
import HIDHandle from '../components/HIDHandle';

export default {
  data() {
    return {
      languages:[
        {
          value:"zh-cn",
          text:"简体中文",
          icon:"el-icon-left"
        },
        {
          value:"en",
          text:"English",
          icon:"el-icon-left"
        },
      ],
      language:"zh-cn",
      dialogVisible:false,
      dialogText:"",
      startPair:"开始配对",
      pairTimerID:null,
      pairResult:HIDHandle.pairResult,
      pairing:false,
      dongleVersion:'v1.21',
      mouseVersion:'V1.24',
      updateVisiable:false,
      updatePrecentage:50,
      updateResult:true,
      changeLog:"The latest version is V13.6, and the updated content is as follows:" + "\n1. Optimized cursor jitter during mouse use" + "\n2. xxxxxxxxxxxxxxxx" + "\n3. xxxxxxxxxxxxxxx" + "\n......",
      updateLinkShow:false,
      updateHref:"",
      updateVersion:"v0.0"
    }
  },
  methods: {
    handleBackClick() {
      this.$bus.$emit('enterSetting',false);
    },
    handleLanguageChange() {
      this.$bus.$emit("languageChange",this.language);

    },
    handleLanguageClick(value) {
      this.language = value;
      this.$bus.$emit("languageChange",this.language);
    },
    handlePairClick() {
      this.dialogText = "请让鼠标进入配对状态（同时按住左中右键3秒，直至对码指示灯快闪），并靠近接收器 ，点击“开始配对”或按键盘的空格键";
      this.dialogVisible = true;
    },
    async handleStartPairClick() {

      if(this.pairResult.pairStatus == 0x03) {
        if(this.pairing == false) {
          this.dialogVisible = false;
        }
      }
      else if(this.pairResult.pairStatus == 0x02 || this.pairResult.pairStatus == 0x00){
          if(this.pairing == false) {
          await HIDHandle.enterPairMode();
          this.pairing = true;
        }
      }
    },
    handleStartPairMouseEnter() {
      console.log("handleStartPairMouseEnter");
      if(this.pairResult.pairStatus == 0x02) {
        this.startPair = "开始配对";
      }
    },
    async getPairResult() {
      await HIDHandle.getPairResult();
    },
    handleCancelClick() {
      if(this.pairing == false) {
          this.dialogVisible = false;
        }
    },
    hangleRestoreClick() {
      this.$confirm('确定恢复出厂设置吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await HIDHandle.setDeviceRestore();
      }).catch(() => {         
      });
    },
    isNeedUpdate(v1,v2) {
      const parts1 = v1.split('.').map(Number);
      const parts2 = v2.split('.').map(Number);
 
      for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
        const num1 = parts1[i] || 0;
        const num2 = parts2[i] || 0;
 
        if (num1 > num2) return true;
        if (num1 < num2) return false;
      }
 
      return false;
    }
  },
  watch: {
    "pairResult.pairStatus":{
      handler() {
        console.log("pair result:",this.pairResult);
        if (this.pairResult.pairStatus == 3)
        {
          this.startPair = "配对成功";
          this.pairing = false;
        }
        else if (this.pairResult.pairStatus == 2)
        {       
          this.startPair = "配对失败";
          this.pairing = false;
        }
      },
      deep: true
    }
  },
  created() {
    this.languages = this.$gLanguages;
    console.log("setting languages",this.languages);
  },
  beforeMount() {
    this.$bus.$on("languageChange",value => {
      if(this.language != value) {
        this.language = value;
      }
    })   
  },
  mounted() {
    this.$bus.$on("updateVersion",value => {
      this.dongleVersion = value.dongle;
      this.mouseVersion = value.device;
    })

    this.$bus.$on("updateUI",value => {
      console.log("updateVersion setting",value);
      this.updateLinkShow = this.isNeedUpdate(this.updateVersion.replace("v",""),this.mouseVersion.replace("v",""));
    })

    this.$bus.$on('getDeviceInfo',value => {
      if(typeof value.Update != "undefined") {  
        this.updateVersion = value.Update.device.version;    
        this.updateHref = window.location.href + value.Update.device.link;

        console.log("update:",value,this.updateHref);
      }
      else {
        this.updateLinkShow = false;
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("updateVersion");
    this.$bus.$off("updateUI");
    this.$bus.$off("languageChange"); 
  }
}
</script>

<style lang="less" scoped>
 .fontSize_16px{
  font-size: 16px;
 }

 .fontSize_Title{
  font-size: 20px;
 }

 .el-button {
  font-size: 16px;
  background-color: transparent;
  color: white;
 }

 :deep(.el-radio__input) {
  display: none;
 }
</style>