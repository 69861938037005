<template>
  <div>
    <div style="display: flex; flex-direction: column;padding: 20px;gap: 20px;">
      <div style="display: flex; flex-direction: row;align-items: center; justify-content: flex-end;">
        <select 
        class="lanSelect" 
        v-model="selectLan" 
        @change="lanChange">
          <option v-for="item in languages" :key="item.value" :value="item.value" >{{ item.text }}</option>
        </select>
        <el-button  @click="handlePairClick" 
        style="background-color: transparent;border: 0px;color: white;font-size: 32px; padding: 12px 20px;" 
        class="iconfont icon-HomePair"></el-button>
      </div>
      <div style="display: flex;flex-direction: column; margin-top: 80px;">
        <div style="display: flex;flex-direction: column;align-items: center;padding: 20px;">
          <p style="font-size: 48px;" class="welcome" lan="Welcome">欢迎使用Scyrox Web Drive</p> 
          <p style="font-size: 18px;max-width: 1000px;margin-top: 50px;margin-bottom: 50px;" lan="SelectDevice">
            在这里您可以迅速配置您的Scyrox设备，尝试一些与众不同的设定，并按您的喜好个性化定制您的设备。当完成配置之后，后台都不用留，只需要保存、关闭、享受即可。
          </p>
          <h2 v-show="isSupportHID == false" lan="NoSupportHID">当前浏览器不支持连接设备,我们推荐您使用Chrome、Edge或Opera浏览器</h2>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: center;">
          <div 
          v-for="(image,index) in devicesPaths" 
          :key="index">
            <img :src="image" @click="connectDevice(index)">
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="pairDialogVisible"
      :close-on-click-modal="false"
      @close="handlePairClose"
      width="30%">
      <span style="color:white">{{pairText}}</span>
      <span  slot="footer" class="dialog-footer">
        <el-button @click="handleCancelClick" lan="Cancel">取 消</el-button>
        <el-button type="primary" @click="handleStartPairClick" @mouseenter="handleStartPairMouseEnter">{{startPair}}</el-button>
      </span>
    </el-dialog> 
  </div>
</template>

<script>
import HIDHandle from '../components/HIDHandle';
  export default {
    data() {
      return {
        languages:[],
        selectLan:"zh-cn",
        isSupportHID:true,
        devicesPaths:[
          './img/1Home/dev1online.png',
          './img/1Home/dev2online.png',
        ],
        language:{},
        pairDialogVisible:false,
        pairText:"",
        startPair:"开始配对",
        pairing:false,
        pairResult:HIDHandle.pairResult,
      }
    },
    methods: {
      lanChange() {
        
        this.$bus.$emit("languageChange",this.selectLan);

        import(`../lan/${this.selectLan}.json`).then(lang => {
          this.language = lang;
          this.startPair = this.language.StartPair;

          
        console.log("home lanChange",this.language,this.selectLan);
        });

      },
      //获取浏览器信息，HID服务仅支持“Chrome”，“Edge”和“Opera”，不支持“safari”和“FireFox”
      getBrowserInfo() {
        let types = ['edg','firefox','chrome','safari','opera '];
        let userAgent = navigator.userAgent.toLocaleLowerCase();
        var res = [];

        types.forEach(element => {
            if(userAgent.indexOf(element)>0){
                let rule= `${element}`+"\\/([\\d.]+)";
                res.push(element);
                res.push(userAgent.match(rule)[1]);
            }
        });

        if(res.indexOf('chrome') >-1 && res.indexOf('safari') >-1){
            if(res.length===4){
                let temp=[];
                temp.push('chrome');
                temp.push(res[res.indexOf('chrome')+1]);
                res = temp;
            }else{
            res.splice(res.indexOf('chrome'),2);
            res.splice(res.indexOf('safari'),2);
            }
        }

        if(res[0] === "chrome" || 
          res[0] === "edg" ||
          res[0] === "opera")
          {
            this.isSupportHID = true;
          }
          else 
            this.isSupportHID = false;
      },
      // 选择需要操作的HID设备
      async connectDevice(index) {
        if(this.isSupportHID) {
          var connect = false;

          var filters = [];
          console.log("filter:",this.$gConfig.Opt);
          var pid = [];
          var i = 0;
          for(i = 0;i < this.$gConfig.Opt.PID.wireless.length;i++) {
            pid.push(this.$gConfig.Opt.PID.wireless[i]);
          }

          for(i = 0;i < this.$gConfig.Opt.PID.wired.length;i++) {
            pid.push(this.$gConfig.Opt.PID.wired[i]);
          }          

          for(let i = 0;i < pid.length;i++)
          {
            let filter = {
              vendorId: Number.parseInt(this.$gConfig.Opt.VID),
              productId: Number.parseInt(pid[i]),
            }
            filters.push(filter);
          }

          console.log("filter:",filters);
          connect = await HIDHandle.requestDevice(filters);
          if(connect)
          {
            var info = await HIDHandle.setEncryption();
            console.log("info",info);

            for(var i = 0;i < this.$gConfig.Dev.length;i++) {
              if(info.cid == this.$gConfig.Dev[i].CID && info.mid == this.$gConfig.Dev[i].MID) {
                console.log("Device Cfg",this.$gConfig.Dev[i]);
                this.$bus.$emit("setMouseImageIndex",i);
                this.$bus.$emit("getDeviceInfo",this.$gConfig.Dev[i]);
                this.$bus.$emit('enterMain',false);

                await HIDHandle.connect();
                window.removeEventListener('keydown', this.handleKeyDown);
              }
            }
          }  
        }
      },
      async handlePairClick() {
        if(this.pairDialogVisible)
          return;

        var connect = false;
        var filters = [];
        var pid = [];
        var i = 0;
        for(i = 0;i < this.$gConfig.Opt.PID.wireless.length;i++) {
          pid.push(this.$gConfig.Opt.PID.wireless[i]);
        }      

        for(let i = 0;i < pid.length;i++)
        {
          let filter = {
            vendorId: Number.parseInt(this.$gConfig.Opt.VID),
            productId: Number.parseInt(pid[i]),
          }
          filters.push(filter);
        }

        console.log("filter:",filters);
        connect = await HIDHandle.requestDevice(filters);
        if(connect)
        {
          console.log("handlePairClick");
          console.log("home lanChange",this.language,this.language.Dialog.PairTips);
          this.pairText = this.language.Dialog.PairTips;
          this.pairDialogVisible = true;
          this.pairResult.pairStatus = 0;
        } 
      },
      async handleStartPairClick() {
        console.log("handleStartPairClick");
        if(this.pairResult.pairStatus == 0x03) {
          if(this.pairing == false) {
            this.pairDialogVisible = false;
          }
        }
        else if(this.pairResult.pairStatus == 0x02 || this.pairResult.pairStatus == 0x00){
          if(this.pairing == false) {
            await HIDHandle.enterPairMode();
            this.pairing = true;
          }
        }
      },
      handleStartPairMouseEnter() {
        console.log("handleStartPairMouseEnter");
        if(this.pairResult.pairStatus == 0x02) {
          this.startPair = this.language.StartPair;
        }
      },
      async getPairResult() {
        await HIDHandle.getPairResult();
      },
      handlePairClose() {
        this.pairResult.pairStatus = 0x00;
        this.startPair = this.language.StartPair;
      },
      handleCancelClick() {
        if(this.pairing == false) {
          this.pairDialogVisible = false;
        }
      },
      async handleKeyDown(event) {   
        if((this.pairing == false)) {
          if (event.code === 'Space') {
            // 执行某些操作
            if(this.pairDialogVisible) {
              this.handleStartPairClick();
            }
            else {
              this.handlePairClick();
            }
          }
        }
      },
    },
    watch:{
      "pairResult.pairStatus": {
      handler() {
        if(this.pairResult.pairStatus == 0x03) {
          this.startPair = this.language.PairSuccess;
          this.pairing = false;
        }
        else if(this.pairResult.pairStatus == 0x02) {
          this.startPair = this.language.PairFail;
          this.pairing = false;
        }
        else if(this.pairResult.pairStatus == 0x01) {
          this.startPair = this.language.Pairing;
        }
      }
    }
    },
    created() {
      this.languages = this.$gLanguages;

      this.getBrowserInfo();

      for(var i = 0;i < this.$gConfig.Dev.length;i++)
      {
        this.devicesPaths[i] = './img/1Home/dev' + (i+1) + 'online.png';
      }

      this.$bus.$on("languageChange",value => {
        import(`../lan/${value}.json`).then(lang => {
          localStorage.setItem('lan',JSON.stringify(value));
          let elements = document.querySelectorAll('[lan]');

          // 遍历每个需要翻译的元素
          elements.forEach(element => {
          let id = element.getAttribute('lan');  
          if (lang[id]) {
              element.innerText = lang[id]; // 使用翻译后的文本替换原来的文本
            }
          });
        });
      });

      this.$bus.$on('enterMain',value => {
        if(value) {
          window.addEventListener('keydown', this.handleKeyDown);
        }
      });
      //reload
    },
    mounted() {
      var lan = localStorage.getItem('lan');
      if(lan) {
        var tmp = lan.replace(/['"]/g, '');
        this.selectLan = tmp;
        this.lanChange();
      }
      else
      {
        if(navigator.language) {

          var lans = [];
          for(var i = 0;i < this.languages.length;i++) {
            lans.push(this.languages[i].value);
          }

          console.log("language:",navigator.language,this.languages,lans);

          if(lans.includes(navigator.language.toLowerCase())) {
            this.selectLan = navigator.language.toLowerCase();
          }
          else
            this.selectLan = "en";
          this.lanChange();
        }
      }
      window.addEventListener('keydown', this.handleKeyDown);
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeyDown);
      this.$bus.$off('enterMain');
      this.$bus.$off('languageChange');
    }
  }
</script>

<style lang="less" scoped>
select {
  position: relative;
  right:20px;
  width: 100px;
  background-color: #131313;
  color: #fff;
}

.el-carousel__item h3 {
  color: transparent;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: transparent;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: transparent;
}

:deep(.el-dialog) {
  background-image: url('../img/2Main/dialog.png');
  background-repeat: no-repeat;
  background-size: cover;

  .el-button {
    height: 22px;
    font-size: 9px;
    color:white;
    background-color: transparent;
    padding: 0px 20px;
    &:hover {
      color:#ffffff;
      background-color: transparent;
      border-color: rgb(238,236,82);
    }
    &:focus {
      color:#ffffff;
      background-color: transparent;
      border-color: rgb(238,236,82);
    }
  }
}
</style>