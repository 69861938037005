<template>
  <div style="display: flex; flex-direction: column;justify-content: space-around;">
    <common-battery v-show="batteryShow" style=" width: 130; height: 60px; margin-left: 370px;"/>
    <div v-show="batteryShow == false" style="width: 130; height: 60px; margin-left: 370px;"></div>
    <common-profile />
    <common-mouse style="margin: 20px; width: 800px;height: 500px;"/>
    <common-debounce style="margin: 30px;"/>
  </div>
</template>

<script>
import HIDHandle from '@/components/HIDHandle';
import CommonDebounce from '@/components/CommonDebounce.vue';
import CommonBattery from '../components/CommonBattery.vue';
import CommonMouse from '../components/CommonMouse.vue';
import CommonProfile from '../components/CommonProfile.vue';

export default {
  data() {
    return {
      batteryShow:false,
      info:HIDHandle.mouseCfg
    }
  },
  components: {
    CommonBattery,
    CommonMouse,
    CommonProfile,
    CommonDebounce,
  },
  watch: {
    "info.connectState": {
        handler() {
          if(this.info.connectState == HIDHandle.DeviceConectState.Connected) {       
            this.batteryShow = true;
          }
          // else if(this.info.connectState == HIDHandle.DeviceConectState.Connecting){
          //   this.batteryShow = false;
          // }
        },
        deep: true
      },
  },
  created() {
    

    this.$bus.$on('enterMain',value => {
      this.batteryShow = false;
    });
  }
}
</script>

<style lang="less" scoped>
  .margin_50px {
    margin: 0px 50px;
  }
</style>