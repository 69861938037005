<template>
  <div style="display: flex; flex-direction: column;align-items: center;">

    <div style="display: flex; flex-direction: row;justify-content: space-evenly;">
      <mouse style="margin-top: 40px;"/>
      <div style="display: flex; flex-direction: column;max-width: 600px;">
        <div style="display: flex; justify-content: flex-end; width: 100%; margin: 10px;">
          <el-button
          icon="el-icon-s-home"
          size="medium"
          @click="handleHomeClick">
          <span lan="Home">主页</span></el-button>
          <el-button 
          icon="el-icon-s-operation" 
          size="medium" 
          @click="handleDeviceClick">
          <span lan="Device">设备</span></el-button>
          <el-button 
          icon="el-icon-setting" 
          size="medium" 
          @click="handleSettingClick">
          <span lan="Setting">设置</span></el-button>
        </div>
        <macro /> 
        <sensor style="margin-top: 30px;"/>
      </div>
    </div>

    <!-- 设备离线和正在更新UI的弹窗 -->
    <common-dialog title=""
    :visible.sync="dialogVisible"
    :content="dialogText"
    :showButton="dialogButton"
    @dialog-result="dialogResult"></common-dialog>
  </div>
</template>

<script>
import Mouse from './Mouse'
import Macro from './Macro'
import Sensor from '../views/SenSor'
import HIDHandle from '../components/HIDHandle'
import CommonDialog from '../components/CommonDialog'

export default {
  components: {
    Mouse,
    Macro,
    Sensor,
    CommonDialog
  },
  data() {
    return {
      info:HIDHandle.deviceInfo,
      cfg:HIDHandle.mouseCfg,
      online:false,
      dialogVisible:false,
      dialogText:"",
      dialogButton:false,
      language:{},
      isHome:false,
      newVersion:"v0.0",
      newVersionTips:false,
      updateHref:"",
      notify:null,
      message:"",
      haveNewVersion:false,
      notifyClose:false
    }
  },
  methods: {
    handleHomeClick() {
      HIDHandle.closeDevice();
      this.notify.close();
      this.notify = null;
      this.newVersionTips = false;
      this.$bus.$emit('enterMain',true);
    },
    handleDeviceClick() {

    },
    handleSettingClick() {
      this.$bus.$emit('enterSetting',true);
    },
    dialogResult() {
      this.dialogVisible = false; 
    },
    isNeedUpdate(v1,v2) {
      const parts1 = v1.split('.').map(Number);
      const parts2 = v2.split('.').map(Number);
 
      for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
        const num1 = parts1[i] || 0;
        const num2 = parts2[i] || 0;
 
        if (num1 > num2) return true;
        if (num1 < num2) return false;
      }
 
      return false;
    }
  },
  mounted() {
    this.$bus.$on("languageChange",value => {
      import(`../lan/${value}.json`).then(lang => {
        this.language = lang;

        if(this.notify != null) {
          this.notify.close();
          this.message = `<a href="${this.updateHref}" target="_blank" style="color:white">${this.language.Dialog.DeviceUpdateTips}</a>`;

          this.notify = this.$notify({
            dangerouslyUseHTMLString: true,
            message: this.message,
            duration: 0,
            offset:30,
          }) 
        }
     
      });
    }) 
    
    this.$bus.$on('enterMain',value => {
      if(value == false) {
        this.isHome = true;
      }
    });

    this.$bus.$on('getDeviceInfo',value => {
      if(typeof value.Update != "undefined") {  
        this.newVersion = value.Update.device.version; 
        this.updateHref = window.location.href + value.Update.device.link;   
        this.haveNewVersion = true;
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off('languageChange');
    this.$bus.$off('enterMain');
    this.$bus.$off('getDeviceInfo');
  },
  watch: {
    "cfg.connectState": {
      handler() {
        if(this.cfg.connectState == HIDHandle.DeviceConectState.Connecting) {
          this.dialogButton = false;
          this.dialogVisible = true;
          this.dialogText = this.language.Dialog.Updating;
        }
        else if(this.cfg.connectState == HIDHandle.DeviceConectState.Connected){
          this.dialogVisible = false;
          console.log("device updateUI:",this.cfg);
          this.$bus.$emit("updateUI",this.cfg);

          if(this.haveNewVersion) {
            if(this.isNeedUpdate(this.newVersion.replace("v",""),this.info.version.device.replace("v",""))) {
              if(this.newVersionTips == false) {
                this.newVersionTips = true;

                this.message = `<a href="${this.updateHref}" target="_blank" style="color:white">${this.language.Dialog.DeviceUpdateTips}</a>`;

                this.notify = this.$notify({
                  dangerouslyUseHTMLString: true,
                  message: this.message,
                  duration: 0,
                  offset:30,
                })
              }
            }
          }     
        }
      },
      deep: true
    },
    //显示设备离线的弹窗
    "info.showOfflineDialog": {
      handler() {
        if(this.info.showOfflineDialog) {
          this.dialogButton = true;
          this.dialogVisible = true;
          this.dialogText = this.language.Dialog.Offline;

          this.info.showOfflineDialog = false;
        }
      },
      deep: true
    },
    //设备在不在线发生变化
    "info.online":{
      handler() {
        if(this.online == false)
        {
          if(this.info.online)
          {
            this.dialogButton = false;
            this.dialogVisible = true;
            this.dialogText = this.language.Dialog.Updating;
          }
        }
        else
        {
          // if(this.info.online == false) {
          //   this.dialogButton = true;
          //   this.dialogVisible = true;
          //   this.dialogText = this.language.Dialog.Offline;
          // }
        }
        this.online = this.info.online;
      }
    }, 
    //正在恢复出厂设置
    "info.isRestoring": {
      handler() {
        if(this.info.isRestoring) {
          this.dialogButton = false;
          this.dialogVisible = true;
          this.dialogText = this.language.Dialog.Restoring;
        }
        else {
          this.dialogVisible = false;
        }
      },
      deep: true
    },

    //断开连接就返回首页
    "info.connection": {
      handler() {
        if(this.info.connection == false) {
          this.notify.close();
          this.notify = null;
          this.newVersionTips = false;
          this.$bus.$emit('enterMain',true);
        }
      }
    },
    //当前DPI发生变化
    "cfg.currentDpi": {
      handler() {
        this.$bus.$emit("updateCurrentDPI",this.cfg.currentDpi);
      },
      deep: true
    },
    //报告率发生变化
    "cfg.reportRate": {
      handler() {
        this.$bus.$emit("updateReportRate",this.cfg.reportRate);
      },
      deep: true
    },

    //鼠标信息
    "info.battery": {
      handler() {
        this.$bus.$emit("updateBattery",this.info.battery);
      },
      deep: true
    },
    //切换配置
    "info.profile": {
      handler() {
        this.$bus.$emit("updateProfile",this.info.profile);
      },
      deep: true
    },
    //设备版本
    "info.version": {
      handler() {
        this.$bus.$emit("updateVersion",this.info.version);
      },
      deep: true
    },     
  }
}
</script>

<style lang="less" scoped>
  .el-button{
    background: transparent;
    border: 0px solid white;
    color: white;
    font-size: 20px;
  }
</style>