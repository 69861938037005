<template>
  <div>
    <el-select 
    v-model="profile" 
    @change="handleProfileChange"
    name="profile" 
    :teleported='false'>
      <el-option           
        v-for="(item,index) in profiles"
        :key="index"
        :label="item.option" 
        :value="item.value"
        style="font-size: 16px">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import HIDHandle from '../components/HIDHandle'

export default {
  data() {
    return {
      profile:0,
      profiles:[],
    }
  },
  methods: {
    async handleProfileChange() {
      await HIDHandle.setProfile(this.profile);
    }
  },
  mounted() {
    this.$bus.$on("updateProfile",value => {
      this.profile = value;
    });

    this.$bus.$on("languageChange",value => {
      import(`../lan/${value}.json`).then(lang => {
        this.profiles = lang.ProfileOption;
      });
    });
  },
  beforeDestroy() {
    this.$bus.$off("updateProfile");
    this.$bus.$off("languageChange");
  }
}
</script>

<style lang="less" scoped>
  .el-select {
    width: 120px;
    background: transparent;
  }
  :deep(.el-input__inner) {
    background-color: transparent;
    border: 0px solid white;
    border-radius: 0px;
    color: white;
  }
</style>