<template>
  <div>
    <div style="display: flex; flex-direction: row; align-items: center;justify-content: space-between;">
      <span lan="DPISetting">DPI设置</span>
      <div style="display: flex;flex-direction: row;align-items: baseline;">
        <div style="display: flex;flex-direction: column;">
          <div class="slider_runway" id="runway" :style="{width:silerLen + 18 + 'px'}">
          </div>
          <div style="position: relative; display: flex;flex-direction: row;" :style="{width:silerLen + 'px'}">
            <div v-for="(value, index) in dpiParams" :key="index" style="display: flex; flex-direction: column; align-items: center;">
              <label 
              @dblclick="handleLabelDbClick"
              :style="{top:'-50px', 
              left:  Number(dpiParams[index].left.replace(/px$/, '')) - 23 * index + 'px',
              color:currentDpi == index? dpiParams[index].color : 'rgba(255,255,255,0.2)',
              position:'relative'}">
              {{dpiParams[index].value}}
              </label>
              <el-button
              :style="{top:'-28px', 
              left:dpiParams[index].left,
              backgroundColor:currentDpi == index? dpiParams[index].color : '#949494',
              borderColor:currentDpi == index? dpiParams[index].color : '#949494',}"
              @click="handleClick(index)"
              :id="'btn' + index"
              v-draggable="setDpiValue">
              {{index + 1}}
              </el-button>
            </div>
          </div>
        </div>
        <el-input-number 
        v-model="dpiCount" 
        :min="1"
        :max="maxDpi"
        @change="handleDpiCountChange"
        size="mini" 
        style="width: 80px;" ></el-input-number>
      </div>
    </div>
  </div>
</template>

<script>
import HIDHandle from '../components/HIDHandle'

export default {
  data() {
    return {
      silerLen:400,
      min:50,
      max:26000,
      currentDpi:0,
      maxDpi:5,
      dpiCount:4,
      dpiParams:[{
        value:400,
        color:'#ff0000',
        left:'10px',
        },
        {
          value:800,
          color:'#00ff00',
          left:'50px',
        },
        {
          value:1200,
          color:'#0000ff',
          left:'100px',
        },
        {
          value:20000,
          color:'#ffff00',
          left:'200px',
        },
      ],
      mouseDpiParams:[],
    };
  },
  methods: {
    handleLabelDbClick() {
      console.log('handleLabelDbClick');
    },
    async handleClick(index) {
      // 处理滑块值改变时的逻辑
      this.currentDpi = index;
      await HIDHandle.setCurrentDPI(index);
    },
    async setDpiValue(data) {
      this.currentDpi = data.index;
      var value = Math.round(data.value * this.max / this.silerLen / 50 + 1) * 50;
      this.dpiParams[this.currentDpi].value = value;
      this.dpiParams[this.currentDpi].left = data.value + 'px';

       if(data.send)
       {
        await HIDHandle.setCurrentDPI(this.currentDpi);
        await HIDHandle.setDPIValue(this.currentDpi,value);
       }
    },
    async handleDpiCountChange() {
      await HIDHandle.setMaxDPI(this.dpiCount);
      console.log("handleDpiCountChange",this.dpiParams,this.currentDpi,this.dpiCount);

      if(this.dpiParams.length < this.dpiCount) {
        let tmp = {
          value : this.mouseDpiParams[this.dpiParams.length].value,
          color : this.mouseDpiParams[this.dpiParams.length].color,
          left : this.mouseDpiParams[this.dpiParams.length].left,
        }
        this.dpiParams.push(tmp);
      }
      else {
          if(this.currentDpi >= (this.dpiCount - 1))
          {
            this.dpiParams.splice(this.currentDpi - 1, 1, this.dpiParams[this.currentDpi]);
            this.currentDpi = this.dpiParams.length - 1;
            await HIDHandle.setCurrentDPI(this.currentDpi);
          }
          else
            this.dpiParams.splice(this.dpiParams.length - 1, 1, this.dpiParams[this.currentDpi]);     
      } 
    }
  },
  mounted() {
    this.$bus.$on("updateUI",value => {
      this.dpiParams = [];
      this.mouseDpiParams = [];
      for(var i = 0;i < 8;i++)
      {
        let left = value.dpis[i].value * this.silerLen / this.max + "px"
        var param = {
          value:value.dpis[i].value,
          color:value.dpis[i].color,
          left:left,
        };

        if(i < value.maxDpi)
          this.dpiParams.push(param);
        this.mouseDpiParams.push(param);
      }
      console.log("dpi left:",this.dpiParams,this.mouseDpiParams);
      this.currentDpi = value.currentDpi;
      this.dpiCount = value.maxDpi;
    })

    this.$bus.$on("updateCurrentDPI",value => {
      console.log("updateCurrentDPI",value);
      this.currentDpi = value;
    })  
  },
  beforeDestroy() {
    this.$bus.$off("updateUI");
    this.$bus.$off("updateCurrentDPI");
  },
  directives: {
    draggable: {
      bind(el,binding) {
        let x,y, left,start,id,tmp;
        el.onmousedown = e => {
          x = e.clientX;
          y = e.clientY;
          start = Number(el.style.left.replace(/px$/, ''));

          document.onmousemove = moveEvent;
          document.onmouseup = mouseUp;
        };
 
        function moveEvent(e) {
          left = start - (x - e.clientX);

          let width = Number(document.getElementById('runway').style.width.replace(/px$/, ''));
          width = width - 18;
          id = el.id.replace('btn', '');
          if(left < 0)
          {
            left = 0;
          }
          else if(left > width)
          {
            left = width;
          }

          let index = Number(id);
          tmp = {
            index:index,
            value:left,
            send:false
          };
          binding.value(tmp);

          console.log("mouse move",tmp);
          if((y - e.clientY > 20) || (y - e.clientY < -20))
          {
            mouseUp(e);
          }
        }
 
        function mouseUp(e) {
          left = start - (x - e.clientX);

          let width = Number(document.getElementById('runway').style.width.replace(/px$/, ''));
          width = width - 18;
          id = el.id.replace('btn', '');
          if(left < 0)
          {
            left = 0;
          }
          else if(left > width)
          {
            left = width;
          }

          let index = Number(id);
          tmp = {
            index:index,
            value:left,
            send:true
          };
          tmp.send = true;
          console.log("mouse up:",el);
          binding.value(tmp);
          document.onmousemove = null;
          document.onmouseup = null;
        };
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .slider_runway {
    width: 400px;
    height: 6px;
    margin: 16px 0;
    background-color: #949494;
    border-radius: 3px;
    position: relative;
  }

  :deep(.el-button) {
    height: 18px;
    width: 18px;
    border-radius: 40%;
    padding: 0px 0px;
    position: absolute;
  }

  .el-input-number {
    border: 0px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    height: 28px;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    color:white;
    border: 0px;
  }

  :deep(.el-input-number__decrease, .el-input-number__increase) {
    background: transparent;
    color:white;
    border: 0px;
  }

  :deep(.el-input-number__increase) {
    background: transparent;
    color:white;
    border: 0px;
  }
</style>