<template>
  <div>
    <div style="display: flex; flex-direction: row; align-items: center;justify-content: space-between;">
      <span lan="PollingRate" style="text-align: left;">报告率</span>
      <div style="display: flex;flex-direction: column;">
        <div class="slider_runway" id="runway" style="width:500px">
        </div>
        <div style="position: relative; display: flex;flex-direction: row; width: 500px;justify-content: space-around;">
          <div v-for="(value, index) in reportRates" :key="index" style="display: flex; flex-direction: column; align-items: center;">
            <label 
            style="top:-50px;position:relative"
            :style="{color:index == reportRate?'#eeec52':'rgba(255,255,255,0.2)'}">
            {{reportRates[index].option}}
            </label>
            <el-button
            style="top:-28px"
            :style="{
              backgroundColor:index == reportRate?'#eeec52':'#949494',
              borderColor:index == reportRate?'#eeec52':'#949494'}"
            @click="handlerReportRateClick(index)">
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HIDHandle from './HIDHandle'

export default {
  data() {
    return {
      reportRate:3,
      reportRates:[],
      defaultReportRates:[]
    }
  },
  methods: {
      reportRateToIndex(reportRate) {
        var found = false;
        for(var i = 0;i < this.reportRates.length;i++)
        {
          var report = this.reportRates[i].rate;
          if(report == reportRate)
          {
            this.reportRate = i;
            found = true;
            break;
          }
        }

        //如果读取的值超过当前最大值，就显示当前最大值
        if(found == false) {
          this.reportRate = this.reportRates[this.reportRates.length - 1].value;
        }
      },
      async handlerReportRateClick(index) {
        this.reportRate = index;
        var report = this.reportRates[index].rate;
        await HIDHandle.setReportRate(report); 
      }
    },
  mounted() {
    this.$bus.$on("updateUI",value => {
      //根据当前设置所支持的最大报告率更新报告率列表
      this.reportRates = [];
      for(var i = 0;i < this.defaultReportRates.length;i++) {
        if(this.defaultReportRates[i].rate <= value.maxReportRate) {
          this.reportRates.push(this.defaultReportRates[i]);
        }
      }

      console.log("update reportRate:",this.reportRates);
      this.reportRateToIndex(value.maxReportRate < value.reportRate ? value.maxReportRate : value.reportRate);
    })

    this.$bus.$on("updateReportRate",value => {
      this.reportRateToIndex(value);
    })
  },
  created() {
    this.$bus.$on("languageChange",value => {
      import(`../lan/${value}.json`).then(lang => {
        //获取默认报告率列表
        this.reportRates = this.defaultReportRates = lang.ReportRates;
      });
    });
  },
  beforeDestroy() {
    this.$bus.$off("updateUI");
    this.$bus.$off("updateReportRate");
    this.$bus.$off("languageChange");
  }
}
</script>

<style lang="less" scoped>
  .slider_runway {
    height: 6px;
    margin: 16px 0;
    background-color: #949494;
    border-radius: 3px;
    position: relative;
  }

  :deep(.el-button) {
    height: 18px;
    width: 18px;
    border-radius: 40%;
    padding: 0px 0px;
    position: absolute;
  }
</style>