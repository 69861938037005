import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui'
import './assets/style/element-variables.scss'
//import 'element-ui/lib/theme-chalk/index.css';
import HIDHandle from './components/HIDHandle'

import config from './cfg.json'
import LanEn from './lan/en.json'
import LanZhCN from './lan/zh-cn.json'

import './icon/iconfont.css'

Vue.config.productionTip = false

//按需引入
Vue.use(ElementUI)
Vue.use(HIDHandle)

Vue.prototype.$gConfig = config;

new Vue({
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this //安装全局在时间总线
    Vue.prototype.$gDeviceCfg;

    Vue.prototype.$gSensorDPI;

    // 获取所有语言
    var languages = [];
    languages.push(LanEn);
    languages.push(LanZhCN);

    // 获取语言下拉框选项
    var lanOptions = [];
    for(let i = 0;i < languages.length;i++)
    {
      let lanSelect = {
        value:languages[i].FileName,
        text:languages[i].Language,
        path:'./img/Lan/' + languages[i].FileName + ".svg"
      }
      lanOptions.push(lanSelect);
    }

    Vue.prototype.$gLanguages = lanOptions;

    document.title = config.Opt.Title;
    console.log("main",config,languages,lanOptions);
  }
}).$mount("#app");
