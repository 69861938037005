import { render, staticRenderFns } from "./CommonDialog.vue?vue&type=template&id=327a1bb6&scoped=true"
import script from "./CommonDialog.vue?vue&type=script&lang=js"
export * from "./CommonDialog.vue?vue&type=script&lang=js"
import style0 from "./CommonDialog.vue?vue&type=style&index=0&id=327a1bb6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327a1bb6",
  null
  
)

export default component.exports