<template>
  <el-dialog 
  :title="title" 
  :visible="visible"
  @close="handleClose"
  custom-class="custom-dialog"
  width="30%">
    <p style="color:white;width: 95%;">{{content}}</p>
    <span slot="footer" class="dialog-footer">
      <el-button v-show="showButton" @click="handleCancel" lan="Cancel">取 消</el-button>
      <el-button v-show="showButton" type="primary" @click="handleConfirm" lan="OK">确 定</el-button>
    </span>
  </el-dialog>
</template>
 
<script>
export default {
  props: {
    title: String,
    visible: Boolean,
    content:String,
    showButton: Boolean,
  },
  methods: {
    handleClose() {
       // 处理提交逻辑
       this.$emit('dialog-result', 2);      
    },
    handleCancel() {
      // 处理提交逻辑
      this.$emit('dialog-result', 0);      
    },
    handleConfirm() {
      // 处理提交逻辑
      this.$emit('dialog-result', 1);
    }
  },
  watch: {
    "dialogVisible" : {
      handler() {
        console.log("dialogVisible change");
      }
    }
  }
}
</script>

<style lang="less" scoped>
  :deep(.el-dialog) {
    background-image: url('../img/2Main/dialog.png');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .el-button {
    height: 22px;
    font-size: 9px;
    color:white;
    background-color: transparent;
    padding: 0px 20px;
    &:hover {
      color:#ffffff;
      background-color: transparent;
      border-color: rgb(238,236,82);
    }
    &:focus {
      color:#ffffff;
      background-color: transparent;
      border-color: rgb(238,236,82);
    }
  }
</style>