<template>
  <div style="display: flex; flex-direction: row;align-items: center;">
    <div style="display: flex; flex-direction: column;align-items: center;">
      <span>{{batteryLevel}}%</span>
      <el-progress 
      :percentage="batteryLevel" 
      :color="customColors"
      :show-text="false" 
      style="width: 100px;"></el-progress>
    </div>
    <el-button 
    v-show="charging"
    icon="iconfont icon-charging" 
    style="background-color: transparent; border: 0px;color:#eeec52; font-size: 40px;"></el-button>
  </div>

</template>

<script>

export default {
  data() {
    return {
      batteryLevel:100,
      charging:false,
      customColors: [
        {color: '#ff0000', percentage: 15},
        // {color: '#e6a23c', percentage: 40},
        // {color: '#5cb87a', percentage: 60},
        // {color: '#1989fa', percentage: 80},
        {color: '#00ff00', percentage: 100}
      ]
    }
  },
  mounted() {
    this.$bus.$on("updateBattery",value => {
      this.batteryLevel = value.level;
      this.charging = value.charging;
    })
  },
  beforeDestroy() {
    this.$bus.$off("updateBattery");
  }
}
</script>

<style lang="less" scoped>

</style>