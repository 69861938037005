<template>
  <div style="display: flex; flex-direction: column;">
    <div style="display: flex; flex-direction: row; align-items: center;">
      <span lan="DPISetting" style="width: 80px; text-align: left;">DPI设置</span>
      <el-slider 
      v-model="dpiValue"
      :min="100"
      :max="30000"
      :step="50"
      show-input
      input-size="mini"
      @change="handleDPIValueChange(dpiValue)" 
      style="width: 480px; margin-left: 40px;"></el-slider>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: center;align-items: center;">
      <div style="display: flex; flex-direction: row; justify-content: space-around; width: 400px; margin-left: 70px;">
        <div
          v-show="refresh"
          v-for="(value,index) in mouseDpiValues"
          :key="index" 
          style="display: flex; flex-direction: column; align-items: center;">
          <span 
          @click="handleDPIIndexClick(index)"
          @dblclick="handleDPIDoubleClick(index)"
          style="border:1px solid rgb(102,102,102);
          border-radius:3px; background-color: rgb(34,34,34); width: 53px; height: 15px;">{{value}}</span>
          <div class="triangle" 
          :style="{display:currentDpi == index?'block':'none'}"></div>
        </div>
      </div>
      <el-input-number 
        v-model="dpiMaxCount"
        @change="handleDPICountChange" 
        :min="1" 
        :max="5"
        size="mini"></el-input-number>
    </div>

    <!-- 输入延时 -->
    <el-dialog
      title=""
      :visible.sync="dialogDPIValueVisible">
      <div style="display: flex;flex-direction: column; align-items: center;">
        <span style="color:white">{{inputDPIValueTips}}</span>
        <el-input 
        v-model.number="inputDPIValue" 
        @input="handleValueInput"
        placeholder="100~30000"
        style="width:80%; margin-top: 5px"></el-input>
      </div>
      <span  slot="footer" class="dialog-footer">
        <el-button @click="handleDPIValueCancelClick" lan="Cancel">取 消</el-button>
        <el-button type="primary" @click="handleDPIValueOKClick" lan="OK">确 定</el-button>
      </span>
    </el-dialog>   
  </div>
</template>

<script>
import HIDHandle from '../components/HIDHandle'

  export default {
    data() {
      return {
        dpiValue:400,
        mouseDpiValues:[400,800,1600,3200,4800],
        dpiValues:[400,800,1600,3200,4800],
        currentDpi:0,
        dpiMaxCount:5,
        refresh:true,//滑条变化时，数字不会变化，所以需要重新show一次
        
        dialogDPIValueVisible:false,
        inputDPIValueTips:"",
        inputDPIValue:200,
        inputIndex:0,

        isRecording:false,
      }
    },
    methods: {
      async handleDPIValueChange(value) {
        this.refresh = false;
        this.mouseDpiValues[this.currentDpi] = value;
        this.dpiValues[this.currentDpi] = value;
        this.refresh = true;
        await HIDHandle.setDPIValue(this.currentDpi,value);
      },
      async handleDPIIndexClick(index) {
        this.currentDpi = index;
        this.dpiValue = this.mouseDpiValues[index];
        await HIDHandle.setCurrentDPI(this.currentDpi);
      },
      handleDPIDoubleClick(index) {
        if(this.isRecording == false) {
          this.inputIndex = index;
          this.inputDPIValue = this.dpiValues[index];
          this.dialogDPIValueVisible = true;
        }
      },
      handleValueInput(value) {
        this.inputDPIValue = value.replace(/[^\d]/g, '');
      },
      handleDPIValueCancelClick() {
        this.dialogDPIValueVisible = false;
      },
      async handleDPIValueOKClick() {
        if(this.inputDPIValue < 100)
          this.inputDPIValue = 100;

        if(this.inputDPIValue > 30000)
          this.inputDPIValue = 30000;
        var value = Math.round(this.inputDPIValue / 50) * 50;
        this.mouseDpiValues[this.currentDpi] = value;
        this.dpiValues[this.currentDpi] = value;
        this.dpiValue = value;
        await HIDHandle.setDPIValue(this.currentDpi,value);
        this.dialogDPIValueVisible = false;
      },
      async handleDPICountChange() {
        //console.log("handleDPICountChange",this.mouseDpiValues,this.dpiValues,this.currentDpi,this.dpiMaxCount);
        if(this.mouseDpiValues.length > this.dpiMaxCount) {
          if(this.currentDpi >= (this.mouseDpiValues.length - 1)) {
            var index = this.dpiMaxCount - 1;
            var current = this.dpiValues[this.currentDpi];
            var last = this.dpiValues[index];
            this.dpiValues.splice(this.currentDpi, 1,last);
            this.dpiValues.splice(index, 1,current);

            for(var i = 0;i < this.mouseDpiValues.length;i++) {
              this.mouseDpiValues[i] = this.dpiValues[i];
            }

            await HIDHandle.setDPIValue(this.currentDpi,this.mouseDpiValues[this.currentDpi]);
            await HIDHandle.setDPIValue(index,this.mouseDpiValues[index]);
            this.currentDpi = this.dpiMaxCount - 1;
            await HIDHandle.setCurrentDPI(this.currentDpi);
          }
          this.mouseDpiValues.splice(this.mouseDpiValues.length - 1, 1);

          await HIDHandle.setMaxDPI(this.dpiMaxCount);
        }
        else {
          this.mouseDpiValues.push(this.dpiValues[this.dpiMaxCount - 1]);
          await HIDHandle.setMaxDPI(this.dpiMaxCount);
        }
      }
    },
    mounted() {
      this.$bus.$on("updateUI",value => {
        this.dpiValues = [];
        this.mouseDpiValues = [];
        for(var i = 0;i < 8;i++)
        {
          if(i < value.maxDpi)
            this.mouseDpiValues.push(value.dpis[i].value);
          this.dpiValues.push(value.dpis[i].value);
        }
        this.currentDpi = value.currentDpi;
        this.dpiMaxCount = value.maxDpi;

        this.dpiValue = this.mouseDpiValues[this.currentDpi];
      })

      this.$bus.$on("updateCurrentDPI",value => {
        console.log("updateCurrentDPI",value);
        this.currentDpi = value;
      })  

      this.$bus.$on("isRecording",value => {
        this.isRecording = value;
      }) 
    },
    beforeDestroy() {
      this.$bus.$off("updateUI");
      this.$bus.$off("updateCurrentDPI");
    },
  }
</script>

<style lang="less" scoped>
  .triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #eeec52;
  }

  :deep(.el-slider__bar) {
    height: 6px;
  }

  .el-input-number {
      border: 0px solid rgba(255, 255, 255, 0.5);
      border-radius: 8px;
    }

  :deep(.el-input__inner) {
    background-color: transparent;
    color:white;
  }

  :deep(.el-input-number__decrease, .el-input-number__increase) {
    background: transparent;
    color:white;
    border: 0px;
  }

  :deep(.el-input-number__increase) {
    background: transparent;
    border: 0px;
  }

  :deep(.el-dialog) {
    background-image: url('../img/2Main/dialog.png');
    width: 30%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .el-button {
    height: 22px;
    font-size: 9px;
    color:white;
    background-color: transparent;
    padding: 0px 20px;
    &:hover {
      color:#ffffff;
      background-color: transparent;
      border-color: rgb(238,236,82);
    }
    &:focus {
      color:#ffffff;
      background-color: transparent;
      border-color: rgb(238,236,82);
    }
  }
</style>