<template>
  <div>
    <div style="display: flex; flex-direction: column;">
      <h1 style="text-align: left;margin: 2px 0px;" lan="Macro">宏</h1>
      <div style="display: flex; flex-direction: row;justify-content: space-between;align-items: center;">
        <span style="font-size:16px" lan="MacroList">宏列表</span>
        <el-button 
        size="mini"
        @click="handleNewMacroClick"
        lan="NewMacro">新建宏</el-button>
      </div>
      <div style="margin-top: 3px;">
        <el-table
        :data="macro"
        @current-change="handleMacroCurrentChange"
        class="macro_table"   
        max-height="150px"    
        :row-class-name="macroRowName"
        :header-cell-style="macroHeaderName">
        <el-table-column
          label="Macro List"
          width="300">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="right">
          <template slot-scope="scope">
            <el-button
              @click="handleEditName(scope.$index, scope.row)"
              icon="el-icon-edit"
              size="mini"></el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDeleteMacro(scope.$index, scope.row)"
              icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
        </el-table>
      </div>
      <div style="display: flex; flex-direction: row;margin-top: 10px; justify-content: space-between;">
        <span style="font-size: 16px;" lan="KeyList">按键列表</span>
      <el-button class="recordButton" ref="recordButton" @click="handleRecordClick"><span :class="recordClass"></span> {{recordLabel}}</el-button>
      </div>
      <div style="display: flex; flex-direction: row;margin-top: 5px;justify-content: space-between;">
        <div style="display: flex; flex-direction: column;">
          <div>
            <el-table
            ref="keyTable"
            :data="selectMacro"
            @current-change="handleKeyCurrentChange"
            class="key-table"
            max-height="280px"
            :row-class-name="keyRowName"
            :header-cell-style="keyHeaderName">
            <el-table-column
              width="200px">
              <template slot-scope="scope">
                <div style="display: flex; flex-direction: row;align-items: center;">
                  <i class="el-icon-top" v-show="scope.row.status == 1"></i>
                  <i class="el-icon-bottom" v-show="scope.row.status == 0"></i>
                  <span style="margin-left: 10px;width: 65px;">{{scope.row.text}}</span>
                  <el-button
                  @click="handleEditKeyClick(scope.$index, scope.row)"
                  size="mini"
                  icon="el-icon-edit"
                  style="margin-left: 5px;"></el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="150px">
              <template slot-scope="scope">
                <div style="display: flex; flex-direction: row;align-items: center;">
                  <i class="el-icon-time" style="margin-left: 10px;"></i>
                  <span style="margin-left: 10px; width: 60px;">{{scope.row.delay}}</span>
                  <el-button
                  @click="handleDelayClick(scope.$index)"
                  size="mini"
                  icon="el-icon-edit"
                  style="margin-left: 5px;"></el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDeleteKeyClick(scope.$index, scope.row)"
                  icon="el-icon-delete"></el-button>
              </template>
            </el-table-column>
            </el-table>
          </div>    
        </div>
        <div style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 10px;">
          <div @keydown="handleKeyDown" @keyup="handleKeyUp"></div>
          <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; height: 50px; margin-top: 20px;">
            <el-radio v-model="autoDelay" label="1"> <span lan="AutoDelay">自动插入延时</span> </el-radio>
            <div style="display: flex; flex-direction: row;align-items: center;">
              <el-radio v-model="autoDelay" label="2"><span lan="DefaultDelay">默认延时</span></el-radio>
              <el-input v-model="defaultDelay" v-show="isRecording == false" @blur="handleDefaultDelayBlur" placeholder="10~65535" size="mini"></el-input>
            </div>
          </div>

          <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; height: 100px; margin-top: 30px;">
            <el-radio v-model="cycleAction" label="254"> <span lan="UntilThisReleased">循环直到此按键松开</span></el-radio>
            <el-radio v-model="cycleAction" label="255"><span lan="UntilAnyPressed">循环直到任意键按下</span></el-radio>
            <el-radio v-model="cycleAction" label="253"><span lan="UntilThisPressed">循环直到此按键再次按下</span></el-radio>

            <div style="display: flex; flex-direction: row;align-items: center;">
              <el-radio v-model="cycleAction" label="1"> <span lan="CycleTimes">循环次数</span></el-radio>
              <el-input v-model="cycleTimes" v-show="isRecording == false" @blur="handleCycleTimesBlur" placeholder="1-250" size="mini"></el-input>
            </div>
          </div>
        
          <div style="display: flex; flex-direction: row;align-items: flex-start; justify-content: space-between; margin-top: 20px;">
            <span lan="InsertEvent">插入事件</span>
            <el-dropdown trigger="click" v-model="insertCommand" @command="handleInsertEventCommand">
            <i class="el-icon-arrow-down el-icon--right"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
              v-for="(key,index) in insertEvent"
              :key="index"
              :command=key.command>{{key.text}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> 
          </div> 
          <el-button @click="handleSaveClick" size="mini" style="margin-top: 10px;" lan="Save">保存</el-button>    
        </div>
      </div>
    </div>

    <!-- 输入宏名称 -->
    <el-dialog
      title=""
      :visible.sync="dialogMacroNameVisible">
      <div style="display: flex;flex-direction: column; align-items: center;">
        <span style="color:white">{{inputMacroName}}</span>
        <el-input 
        v-model="macroName" 
        type="text"
        maxlength="30"
        style="width:80%; margin-top: 5px"></el-input>
      </div>
      <span  slot="footer" class="dialog-footer">
        <el-button @click="handleMacroNameCancelClick" lan="Cancel">取 消</el-button>
        <el-button type="primary" @click="handleMacroNameOKClick" lan="OK">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 输入按键 -->
    <el-dialog
      title=""
      :visible.sync="dialogInputKeyVisible">
      <div style="display: flex;flex-direction: column; align-items: center;">
        <span style="color:white">{{inputKeyTips}}</span>
        <el-input 
        v-model="inputKey" 
        @focus="handleEditKeyFocus"
        @blur="handleEditKeyBlur" 
        @input="handleEditKeyInput"
        ref="inputField"
        style="width:80%; margin-top: 5px"></el-input>
      </div>
      <span  slot="footer" class="dialog-footer">
        <el-button @click="handleInputKeyCancelClick" lan="Cancel">取 消</el-button>
        <el-button type="primary" @click="handleInputKeyOKClick" lan="OK">确 定</el-button>
      </span>
    </el-dialog>  
    
    <!-- 输入延时 -->
    <el-dialog
      title=""
      :visible.sync="dialogInputDelayVisible">
      <div style="display: flex;flex-direction: column; align-items: center;">
        <span style="color:white">{{inputDelayTips}}</span>
        <el-input 
        v-model.number="inputDelay" 
        type="number"
        placeholder="10~65535"
        style="width:80%; margin-top: 5px"></el-input>
      </div>
      <span  slot="footer" class="dialog-footer">
        <el-button @click="handleInputDelayCancelClick" lan="Cancel">取 消</el-button>
        <el-button type="primary" @click="handleInputDelayOKClick" lan="OK">确 定</el-button>
      </span>
    </el-dialog>   
    
    <!-- 删除 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible">
      <span style="color:white">{{dialogTips}}</span>
      <span  slot="footer" class="dialog-footer">
        <el-button @click="handleCancelClick" lan="Cancel">取 消</el-button>
        <el-button type="primary" @click="handleOKClick" lan="OK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import HIDKey from '../components/HIDKey';

export default {
  components: {
    HIDKey
  },
  data() {
    return {
      isRecording:false,
      recordLabel:'开始录制',
      recordClass:"circle",
      autoDelay:'1',
      defaultDelay:10,
      cycleAction:'1',
      cycleTimes:1,
      selectMacro:[],
      currentMacroIndex:-1,
      currentContextIndex:-1,
      contextCount:0,
      lastTimeStamp:0,
      keyInterval:0,
      restartRecord:false,
      editKeyCode:null,
      keyCode:'',
      insertCommand:1,
      insertEvent:[],
      macro:[],

      language:{},
      dialogMacroNameVisible:false,
      macroName:"",
      inputMacroName:"",
      newName:false,

      dialogInputKeyVisible:false,
      inputKey:"",
      inputKeyType:"Edit",
      inputKeyTips:"",

      dialogInputDelayVisible:false,
      inputDelay:10,
      inputDelayTips:"",

      dialogVisible:false,
      dialogTips:"",
      dialogType:"Macro",
      deleteIndex:0,
    }
  },
  methods: {
    stopRecordFirst() {
      if(this.isRecording) {
        this.dialogVisible = true;
        this.dialogType = "StopRecord";
        this.dialogTips = this.language.Dialog.StopRecordFirst;
      }
      return this.isRecording;
    },
    //新建宏
    handleNewMacroClick() {
      if(this.stopRecordFirst() == false) {
        this.dialogMacroNameVisible = true;
        this.newName = true;
      }
    },
    //编辑宏名称
    handleEditName(index, row) {
      if(this.stopRecordFirst() == false) {
        this.currentMacroIndex = index;
        this.dialogMacroNameVisible = true;
        this.newName = false;
      }
    },
    //新建宏/编辑宏取消按钮
    handleMacroNameCancelClick() {
      this.dialogMacroNameVisible = false;
    },
    //新建宏/编辑宏确定按钮
    handleMacroNameOKClick() {
      var found = false;
      for(var i = 0;i < this.macro.length;i++)
      {
        if(this.macro[i].name == this.macroName)
        {
          this.dialogVisible = true;
          this.dialogType = "SameMacro";
          this.dialogTips = this.language.Dialog.SameMacroName;
          found = true;
          break;
        }
      }
      
      if((found == false) && (this.macroName != ""))
      {
        var tmp = {
          name : this.macroName,
          contexts:[],
          cycleTimes:1,
        }

        if(this.newName)
        {
          this.macro.push(tmp);
          console.log(this.macro);
        }
        else
        {
          this.$bus.$emit("updateMacroName",this.macro[this.currentMacroIndex].name,this.macroName);
          this.macro[this.currentMacroIndex].name = this.macroName;
          localStorage.setItem('macro',JSON.stringify(this.macro));
        }
      }
      this.dialogMacroNameVisible = false;
    },
    //删除宏
    handleDeleteMacro(index, row) {
      this.dialogVisible = true;
      this.dialogType = "Macro";
      this.dialogTips = this.language.Dialog.Delete;
      this.deleteIndex = index;
    },
    //选择宏发生变化
    handleMacroCurrentChange(val,oldVal) {
      // 获取当前选中行的索引
      console.log(val);
      if(val != null)
      {
        this.selectMacro = val.contexts;
        this.currentMacroIndex = this.macro.indexOf(val);
        let times = this.macro[this.currentMacroIndex].cycleTimes;
        if(times <= 250)
        {
          this.cycleAction = "1";
          this.cycleTimes = this.macro[this.currentMacroIndex].cycleTimes;
        }
        else
        {
          this.cycleAction = times;
        }
      }
    },
    //选择按键发生变化
    handleKeyCurrentChange(val, oldVal) {
      if(val != null)
      {
        this.currentContextIndex = this.selectMacro.indexOf(val);
        console.log(this.currentContextIndex);
      }
    }, 
    //点击按键编辑按钮
    handleEditKeyClick(index, row) {
      if(this.stopRecordFirst() == false) {
        this.currentContextIndex = index;
        this.inputKeyType = "Edit";
        this.dialogInputKeyVisible = true;
        this.inputKeyTips = this.language.Dialog.InputKey;
      }
    },
    //取消编辑按钮
    handleInputKeyCancelClick() {
      this.dialogInputKeyVisible = false;
    },
    //确定编辑按钮
    handleInputKeyOKClick() {
      if(this.inputKeyType == "Edit") {
        this.selectMacro[this.currentContextIndex].text = this.inputKey;
      }
      else if(this.inputKeyType == "Insert") {
        var keyCode = HIDKey.textToHID(this.inputKey);
        var context ={
          status:this.insertCommand,
          type:1,
          value:keyCode.value,
          delay:10,
          text:keyCode.text,
        };
        var index = this.currentContextIndex;
        if(index == -1) {
          index = 0;
        }
        this.selectMacro.splice(index,0,context);
        this.selectMacro[index].text = keyCode.text;
      }
      this.dialogInputKeyVisible = false;
    }, 
    handleEditKeyFocus() {
      window.addEventListener('keydown', this.handleEditKeyDown);
      console.log('handleEditKeyfocus');
    },
    handleEditKeyBlur() {
      window.removeEventListener('keydown', this.handleEditKeyDown);
      console.log('handleEditKeyBlur');
    },
    handleEditKeyDown(event) {
      var keyCode = HIDKey.keyToHID(event.code);
      this.inputKey = keyCode.text;
      console.log(event,this.inputKey);
      event.preventDefault();
    },
    handleEditKeyInput() {
      console.log("handleEditKeyInput",this.inputKey);
    },
    //点击延时编辑按钮
    handleDelayClick(index) {
      if(this.stopRecordFirst() == false) {
        this.currentContextIndex = index;
        this.dialogInputDelayVisible = true;
        this.inputDelayTips = this.language.Dialog.InputDelay;
      }
    },
    handleInputDelayCancelClick() {
      this.dialogInputDelayVisible = false;
    },
    handleInputDelayOKClick() {
      if(this.inputDelay > 65535)
        this.inputDelay = 65535;
      if(this.inputDelay < 10)
        this.inputDelay = 10;

      this.selectMacro[this.currentContextIndex].delay = this.inputDelay;
      this.dialogInputDelayVisible = false;
    },    
    handleCancelClick() {
      this.dialogVisible = false;
    },
    handleOKClick() {
      if(this.dialogType == "Macro") {
        this.selectMacro=[];
        this.$bus.$emit("deleteMacro",this.macro[this.deleteIndex]);
        this.macro.splice(this.deleteIndex,1);
        localStorage.setItem('macro',JSON.stringify(this.macro));

        this.currentMacroIndex = -1;   
      }
      this.dialogVisible = false;
    }, 
    //删除宏按键列表
    handleDeleteKeyClick(index, row) {
      this.selectMacro.splice(index, 1);
    },
    //点击录制按钮
    handleRecordClick() {
      this.$refs.recordButton.$el.blur();
      console.log(this.selectMacro);
      if(this.currentMacroIndex  == -1)
      {
        this.dialogVisible = true;
        this.dialogType = "SelectMacro";
        this.dialogTips = this.language.Dialog.SelectMacro;
      }
      else
      {
        this.isRecording = !this.isRecording;
      }

      this.recordLabel = this.isRecording ? this.language.StopRecord : this.language.StartRecord;
      if(this.isRecording)
      {
          // 在全屏模式下监听键盘事件
          document.addEventListener('keydown', this.handleKeyDown);
          document.addEventListener('keyup', this.handleKeyUp);  
          this.contextCount = 0;   
          this.currentContextIndex = -1;
          this.restartRecord = true; 
          this.selectMacro = []; 

          if(this.defaultDelay < 10)
            this.defaultDelay = 10;

          this.recordClass = "square";        
      }
      else
      {
        // 组件销毁前移除事件监听
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('keyup', this.handleKeyUp);
        this.recordClass = "circle";
      }

      this.$bus.$emit("isRecording",this.isRecording);
    },
    addKeyContext(event,tmp) {
      if(this.dialogVisible)
        return;
      event.preventDefault();
      if(this.selectMacro.length < 70) {
        var delay = 0;
        if(this.restartRecord) {
          this.restartRecord = false;  
          this.lastTimeStamp = event.timeStamp;          
        }
        else {
          delay = Math.floor(event.timeStamp - this.lastTimeStamp);
          this.lastTimeStamp = event.timeStamp;
        }

        if(this.autoDelay == 2) {
          delay = this.defaultDelay;
        }

        var keyCode = HIDKey.keyToHID(event.code);

        var context ={
          status:tmp.status,
          type:keyCode.type,
          value:keyCode.value,
          delay:0,
          text:keyCode.text,
        };

        if(this.selectMacro.length > 0)
          this.selectMacro[this.selectMacro.length - 1].delay = delay;
        this.selectMacro.push(context);
        this.$nextTick(function() { //此处必须使用nextTick函数,使页面渲染完后再执行
          this.$refs.keyTable.bodyWrapper.scrollTop = 
          this.$refs.keyTable.bodyWrapper.scrollHeight;
        });
      }
      else
      {
        this.dialogVisible = true;
        this.dialogType = "MaxMacroKey";
        this.dialogTips = this.language.Dialog.MaxMacroKey;
      } 
      
      this.currentContextIndex = this.selectMacro.length - 1;
    },
    handleKeyDown(event) {
      if(this.isRecording)
      {
        console.log(event);

        var tmp = {
          status:0,
          type:1,
          value:event.keyCode,
        }
        this.addKeyContext(event,tmp);
      }
    },
    handleKeyUp(event) {
      if(this.isRecording)
      {
        console.log(event);

        var tmp = {
          status:1,
          type:1,
          value:event.keyCode,
        }
        this.addKeyContext(event,tmp);
      }
    },
    //默认延时输入框
    handleDefaultDelayBlur() {
      // 确保输入的数字小于65535
      if (parseInt(this.defaultDelay, 10) > 65535) {
        this.defaultDelay = '65535';
      }

      // 确保输入的数字小于65535
      if (parseInt(this.defaultDelay, 10) < 10) {
        this.defaultDelay = '10';
      }
    },
    //循环次数输入框
    handleCycleTimesBlur() {
      // 确保输入的数字小于65535
      if (parseInt(this.cycleTimes, 10) > 250) {
        this.cycleTimes = '250';
      }

      // 确保输入的数字小于65535
      if (parseInt(this.cycleTimes, 10) < 1) {
        this.cycleTimes = '1';
      }
    },
    // 插入事件选择
    handleInsertEventCommand(command) {
      if(this.currentMacroIndex  == -1) {
        this.dialogVisible = true;
        this.dialogType = "SelectMacro";
        this.dialogTips = this.language.Dialog.SelectMacro;
      }
      else {
        this.insertCommand = command;
        if(command < 2)
        {
          this.inputKeyType = "Insert";
          this.dialogInputKeyVisible = true;
          this.inputKeyTips = this.language.Dialog.InputKey;       
        }
        else
        {
          var index = this.currentContextIndex;

          if(index == -1)
            index = 0;
          var value = command - 2;
          value = 1 << value;

          var context1 ={
            status:0,
            type:4,
            value:value,
            delay:10,
            text:this.insertEvent[command].text,
          };
          this.selectMacro.splice(index + 1,0,context1);

          var context2 ={
            status:1,
            type:4,
            value:value,
            delay:10,
            text:this.insertEvent[command].text,
          };

          this.selectMacro.splice(index + 1,0,context2);
          this.currentContextIndex = index + 2;
          console.log(this.selectMacro);
        }   
      }
        
    },
    handleSaveClick() {
      if(this.stopRecordFirst() == false) {
        if(this.currentMacroIndex != -1) {
          let times = 1;
          if(this.cycleAction == 1) {
            if(this.cycleTimes == 0) {
              this.cycleTimes = 1;
            }
            times = this.cycleTimes;
          }
          else
            times = this.cycleAction;

          this.macro[this.currentMacroIndex].cycleTimes = times;
          this.macro[this.currentMacroIndex].contexts = this.selectMacro;
          localStorage.setItem('macro',JSON.stringify(this.macro));
          this.$bus.$emit("updateMacro",this.macro);
          this.$bus.$emit("saveMacro",this.currentMacroIndex);
          console.log(this.macro,times);
        }
      }
    },
    macroRowName({row, rowIndex}) {
      if(rowIndex == this.currentMacroIndex)
        return 'select-row';
      else
        return 'unselect-row';
    },
    macroHeaderName({row, rowIndex}) {
      return 'background-color: transparent; color:#FFFFFF;';
    },
    keyRowName({row, rowIndex}) {
      if(rowIndex == this.currentContextIndex)
        return 'select-row';
      else
        return 'unselect-row';
    },
    keyHeaderName({row, rowIndex}) {
      return 'background-color: transparent; color:#FFFFFF;'; 
    }
  },
  created() {
    //this.macro = localStorage.getItem('macro');
    let item = localStorage.getItem('macro');
    if (item) {
      try {
        let tmp = [];
        tmp.push(JSON.parse(item));

        for(let i = 0;i < tmp[0].length;i++)
        {
          this.macro.push(tmp[0][i]);
        }

        console.log("macro updateMacro:",this.macro);
        this.$bus.$emit("updateMacro",this.macro);
      } catch (e) {
        console.error('Parsing error', e);
      }
    }

    this.$bus.$on("addMacro",value => {
      this.macro.push(value);
      localStorage.setItem('macro',JSON.stringify(this.macro));
    })

    this.$bus.$on("languageChange",value => {
      console.log('Macro language');
      import(`../lan/${value}.json`).then(lang => {
        this.language = lang;

        this.insertEvent = lang.InsertEventOptions;
        this.recordLabel = lang.StartRecord;
        this.inputMacroName = lang.Dialog.InputMacroName;
        this.inputKeyTips = lang.Dialog.InputKey;
        this.inputDelayTips = lang.Dialog.InputDelay;

        for(var i = 0;i < this.macro.length;i++) {
          var tmp = this.macro[i];
          for(var j = 0;j < tmp.contexts.length;j++) {
            if(tmp.contexts[j].type == 0x04) {
              for(var k = 2;k < lang.InsertEventOptions.length;k++) {
                var value = parseInt(lang.InsertEventOptions[k].value,16);
                if(tmp.contexts[j].value == value) {
                  tmp.contexts[j].text = lang.InsertEventOptions[k].text;
                  break;
                }
              }
            }
          }
        }

        console.log("macro lang",this.language,this.insertEvent,this.macro);
      });
    });
    
    this.$bus.$on("newMacro",value => {
      console.log("update macro in",value);

        var tmp = value;
        for(var j = 0;j < tmp.contexts.length;j++) {
          if(tmp.contexts[j].type == 0x04) {
            for(var k = 2;k < this.language.InsertEventOptions.length;k++) {
              var value = parseInt(this.language.InsertEventOptions[k].value,16);
              if(tmp.contexts[j].value == value) {
                tmp.contexts[j].text = this.language.InsertEventOptions[k].text;
                break;
              }
            }
          }
        }
        console.log("update macro tmp",tmp);
        
        var found = false;
        for(var i = 0;i < this.macro.length;i++) {
          if(this.macro[i].name == tmp.name) {
            this.macro[i] = tmp;
            found = true;
            break;
          }
        }

        if(found == false) {
          this.macro.push(value);    
        }
        
        localStorage.setItem('macro',JSON.stringify(this.macro));
        console.log("update macro out",this.macro);
    })
  },
  beforeDestroy() {
    this.$bus.$off('addMacro');
    this.$bus.$off('languageChange');
    this.$bus.$off('newMacro');
  }
}
</script>

<style lang="less" scoped>
  .macro_table {
    height: 200px;
  }

  .key-table {
    width: 450px;
    height: 280px;
  }

  .el-table {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
  }

  :deep(.el-table::before) {
    height: 0px;
  }

  :deep(.el-table__empty-text) {
    display: none;
  }

  :deep(.el-table .el-table__header-wrapper tr) {
    display: none;
  }

  :deep(.el-table__body tr:hover>td) {
    background-color: rgba(0,0,0,0.3) !important;  
  }

  :deep(.el-table .select-row) {
    background: black;
  }
  
  :deep(.el-table .unselect-row) {
    background: rgba(255,255,255,0.3);
  }

  :deep(.el-table .el-table__cell) {
    padding: 2px 0px;
  }

  .el-input {
    width: 60px;
    font-size: 9px;
    color:white;
  }

  :deep(.el-input--mini .el-input__inner) {
    height: 16px;
    line-height: 16px;
    text-align: center;
  }

  :deep(.el-input__inner) {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 2px;
    color: white;
  }

  :deep(.el-table .el-table__row ){
    height: 16px; /* 你想要的高度 */
    font-size: 10px;
    color: white;
  }

  .el-button {
    height: 22px;
    font-size: 9px;
    color:white;
    background-color: transparent;
    padding: 0px 20px;
    &:hover {
      color:#ffffff;
      background-color: transparent;
      border-color: rgb(238,236,82);
    }
    &:focus {
      color:#ffffff;
      background-color: transparent;
      border-color: rgb(238,236,82);
    }
  }

  :deep(.el-button:active)
  {
    color:#ffffff;
    background-color: transparent;
    border-color: rgb(238,236,82);
  }

  :deep(.el-button .el-button-mini ){
    padding: 0px 10px;
  } 

  :deep(.el-radio__inner) {
    border:0px;
    border-color: transparent;
    background: rgba(255,255,255,0.3);
  }

  :deep(.el-radio__inner::after) {
    content: none;
  }

  :deep(.el-radio__input.is-checked .el-radio__inner) {
    border-color: rgb(238,236,82);
    background: rgb(238,236,82);
    content: none;
  }

  :deep(.el-radio__input.is-checked+.el-radio__label) {
    color:white;
  }

  :deep(.el-radio__label) {
    color:white;
  }

  .el-icon-arrow-down {
    margin-left: 40px;
    cursor: pointer;
    font-size: 14px;
    width: 60px;
    height: 20px;
    border: 1px solid white;
    border-radius: 5px;
  }

  .circle {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: #f00;
    border-radius: 50%;
    margin-right: 5px;
  }

  .square {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: #f00;
    border-radius: 0%;
    margin-right: 5px;
  }

  .recordButton {
    height: 24px;
    width: 200px;
    font-size: 14px;
  }
  .recordButton .el-button {
    padding: 0px 20px;
  }

  :deep(.el-dialog) {
    background-image: url('../img/2Main/dialog.png');
    width: 30%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  :deep(.el-form-item__label) {
    color:white;
  }
</style>